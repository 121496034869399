import React, { useState, useEffect, useRef } from "react";
import styles from "./chatPage.module.less";
import { updateUserWork, createUserWork } from "@/api/userWork";
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import ChatBubble from "@/components/Element/AIChatRegion/ChatBubble";
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import SendDisableIcon from '@/assets/img/send_disable.svg';
import SendAbleIcon from '@/assets/img/send_able.svg';
import { cloneDeep } from "lodash";
import { ChatMessage, MediaInfo } from '@/base/ChatMessage';
import ChatData from "@/base/PlaygroundData/ChatData";
import MultimediaAPI from '@/api/multimedia';
import RegenerateIcon from '@/assets/img/playground/regenerate_icon.svg';
import ShareIcon from '@/assets/img/playground/share_icon.svg';
import DownloadIcon from '@/assets/img/playground/download_icon.svg';
import BasicSharePageInfo from "@/base/ShareData/BasicSharePageInfo";
import { generateMD5 } from "@/utils/utils";
import { message, Tooltip, QRCode } from "antd";
import ImgGenLoading from '@/assets/img/playground/img_gen_loading.gif';
import { genUUIDv4 } from "@/utils/id_generator";

interface ChatPageProps {
    chatInfo: ChatData;
}

const ChatPage: React.FC<ChatPageProps> = ({ chatInfo }) => {
    const { userInfoStore } = useStores();
    const [chatAgentInfo, setChatAgentInfo] = useState<ChatData>(chatInfo);
    const [inputValue, setInputValue] = useState('');
    //上一次的输入值，用来重新生成视屏
    const [lastInputValue, setLastInputValue] = useState<string>('');
    const [isFetching, setIsFetching] = useState(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const imgGenAPI = new MultimediaAPI();
    const [qrUrl, setQrUrl] = useState('');
    const [qrLoading, setQrLoading] = useState(false);


    useEffect(() => {
        setChatAgentInfo(chatInfo);
    }, [chatInfo, chatInfo?.workInfo?.chatList]);

    //发送消息
    const handleSend = async (userInput: string = '') => {
        console.log('send message:', userInput);
        if (isFetching || userInput.trim() === "") return;
        // const diamondsConsumeRes: any = await userInfoStore.consumeDiamonds("video_gen");
        // if (diamondsConsumeRes.status === 'error') {
        //     if (diamondsConsumeRes.message === 'Not enough diamonds') {
        //         message.error('钻石余额不足');
        //         return;
        //     } else {
        //         message.error('扣除钻石失败，请重试');
        //         return;
        //     }
        // }
        const userMessage = new ChatMessage({ type: 3, rawContent: userInput });
        let gptMessage = new ChatMessage(
            {
                type: 4,
                mediaInfo: new MediaInfo({ mediaType: "image", mediaUrl: ImgGenLoading }),
                rawContent: userInput
            });

        const newMessages = [...chatAgentInfo.workInfo.chatList, userMessage, gptMessage];
        chatAgentInfo.workInfo.chatList = newMessages;
        setChatAgentInfo(cloneDeep(chatAgentInfo));
        setIsFetching(true);
        setLastInputValue(userInput);
        setInputValue('');

        // 发送消息到后端
        try {
            let vendor = "";
            if (import.meta.env.VITE_OSS_SITE === 'AWS') {
                vendor = "OpenAI";
            } else if (import.meta.env.VITE_OSS_SITE === 'Aliyun') {
                // 视频模型还是先用zhipu
                vendor = "Zhipu";
            }
            const requestData = {
                request_id: "request_" + genUUIDv4(),
                prompt: userInput,
                config: { vendor: "Zhipu" } // 根据实际需求调整配置
            };
            const taskIdRes: any = await imgGenAPI.videoGenSubmit(requestData);
            if (taskIdRes.status !== 'success') {
                throw new Error('视频任务提交失败');
            }
            // 轮询任务状态（最多500秒）
            const maxTries = 500;
            let i = 0;
            let resp: any;

            while (i < maxTries) {
                await new Promise(r => setTimeout(r, 1000)); // 每秒检查一次
                resp = await imgGenAPI.getGenVideo(taskIdRes.data.task_id);

                if (resp.status === 'success') {
                    // 生成成功，更新消息
                    const videoMessage = new ChatMessage({
                        type: 5,
                        mediaInfo: new MediaInfo({
                            mediaType: "video",
                            mediaUrl: resp.file_url // 根据实际响应结构调整
                        }),
                        rawContent: userInput
                    });

                    const newMessages = cloneDeep(chatAgentInfo.workInfo.chatList);
                    newMessages[newMessages.length - 1] = videoMessage;
                    chatAgentInfo.workInfo.chatList = newMessages;
                    setChatAgentInfo(cloneDeep(chatAgentInfo));

                    // 更新后端存储
                    const backUpAgent = cloneDeep(chatAgentInfo);
                    backUpAgent.userName = userInfoStore.userInfoData.name || '';
                    await updateUserWork(backUpAgent);
                    break;
                }

                i++;
                if (i >= maxTries) {
                    throw new Error('视频生成超时');
                }
            }
        } catch (error) {
            let newGptMessage = new ChatMessage({ type: 1, rawContent: "视频生成失败，请重试" });
            const newMessages = cloneDeep(chatAgentInfo.workInfo.chatList);
            newMessages[newMessages.length - 1] = newGptMessage;
            chatAgentInfo.workInfo.chatList = newMessages;
            setChatAgentInfo(cloneDeep(chatAgentInfo));
        } finally {
            setIsFetching(false);
        }

    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (event.isComposing || event.keyCode === 229) {
                // 在拼音输入法中，按下回车只插入字母，不发送  
                event.preventDefault(); // 阻止默认行为  
            } else {
                // 处理中未使用拼音输入法回车，发送消息  
                event.preventDefault(); // 阻止默认行为  
                handleSend(inputValue);
            }
        }
    };

    // 添加useEffect以处理自动滚动
    useEffect(() => {
        if (chatContainerRef.current) {
            const { current: container } = chatContainerRef;
            // 使用scrollTo方法进行平滑滚动
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth' // 这个设置能使滚动行为变得平滑
            });
        }
    }, [chatAgentInfo?.workInfo?.chatList, chatAgentInfo?.workInfo?.chatList.length]);

    const handleImageLoad = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    //分享图片
    const clickShareIcon = async (imgSrc: string) => {
        setQrLoading(true);
        const wornInfoData = new BasicSharePageInfo({
            title: `这是${userInfoStore.userInfoData.name}的作品`,
            body: '这是我使用AI助手生成的视频，快来看看吧！',
            imgUrl: imgSrc,
            authorName: userInfoStore.userInfoData.name || '',
        })
        const concatenatedString = JSON.stringify(wornInfoData);

        const MD5 = generateMD5(concatenatedString);
        const shareLinkData = {
            id: 0,
            md5: MD5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "basicPost",
            work_info: wornInfoData,
            create_time: 0
        };
        const shareLinkRes: any = await createUserWork(shareLinkData);
        if (shareLinkRes.status === 'success') {
            setQrUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
            setQrLoading(false);
        } else {
            setQrLoading(false);
            message.error('生成分享链接失败');
        }
    };

    return (
        <div className={styles.chatBox}>
            <div className={styles.headerText}>
                视频生成助手
            </div>
            <div
                className={styles.aiChat}
                ref={chatContainerRef}
            >
                {chatAgentInfo?.workInfo?.chatList.length === 0 && (
                    <div className={styles.emptyChat}>
                        欢迎使用视频生成助手
                    </div>
                )}
                {chatAgentInfo?.workInfo?.chatList.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <ChatBubble
                                key={index}
                                item={item}
                                role="gpt"
                                scene={isFetching && index === chatAgentInfo.workInfo.chatList.length - 1 ? "playground_loading" : "playground"}
                                onLoad={handleImageLoad}
                            />
                            {item.type === 5 && !isFetching &&
                                <div className={styles.btnBox}>
                                    {/* 如果是最后一个，并且是图片消息，显示重新生成按钮 */}
                                    {index === chatAgentInfo.workInfo.chatList.length - 1 && lastInputValue && (
                                        <div className={styles.btn} onClick={() => handleSend(lastInputValue)}>
                                            <img src={RegenerateIcon} alt="regenerate" />
                                        </div>
                                    )}
                                    <div className={styles.btn} onClick={() => clickShareIcon(item.mediaInfo?.mediaUrl)}>
                                        <Tooltip
                                            color='white'
                                            trigger='click'
                                            title={<QRCode
                                                value={qrUrl} size={150}
                                                status={qrLoading ? "loading" : "active"} />
                                            }
                                        >
                                            <img src={ShareIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                }
                )}
            </div>
            <div className={styles.inputAreaBox}>
                <div className={`${styles.box}`}>
                    <div className={styles.inputBox}>
                        <textarea
                            className={styles.inputArea}
                            placeholder="输入消息..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div >
                </div>
                <div className={styles.bottomBox}>
                    <div className={styles.onlineSearchBox}>
                    </div>
                    <div className={styles.operationBox}>
                        <div className={styles.audioBtn}>
                            <SpeechToText
                                setInputMessage={setInputValue}
                                inputMessage={inputValue}
                            />
                        </div>
                        <div className={styles.sendBtn}>
                            <img
                                src={inputValue.trim() === '' ? SendDisableIcon : SendAbleIcon}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSend(inputValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(ChatPage);
