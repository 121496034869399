import React, { useState, useEffect } from 'react';
import styles from './home.module.less';
import LogoSvg from '@/assets/img/home_page/logo.svg';
import HeaderVideoIcon from '@/assets/img/home_page/header_video.png';
import Module1Img from '@/assets/img/home_page/module1_img.jpg';
import Module2Img from '@/assets/img/home_page/module2_img.jpg';
import Module3Img from '@/assets/img/home_page/module3_img.jpg';
import Module4Img from '@/assets/img/home_page/module4_img.jpg';
import { translations } from './translations';
import LoginModal from '../LoginModal/LoginModal';
import service from '@/services/axios';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = useState<'en' | 'zh'>('zh');
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    // 语言翻译文本  
    const translatedTexts = translations[language];

    // 语言改变处理函数  
    const changeLanguage = (lang: 'en' | 'zh') => {
        setLanguage(lang);
    };

    const checkLogin = async () => {
        const isLoggedIn = Boolean(localStorage.getItem('token'));
        if (!isLoggedIn) return;

        try {
            const res: any = await service.get('/me');
            if (res.status === 'success') {
                navigate('/')
            }
        } catch (error) {
            console.error('Error checking login status', error);
        }
    }

    //跳转到产品购买页面
    const navigateToProduct = () => {
        window.open('/product_purchase', '_blank');
    }

    //点击视频图标跳转到youtube视频
    const navigateToYoutube = () => {
        window.open('https://www.youtube.com/@%E8%89%BE%E5%AE%9DAI', '_blank');
    }

    useEffect(() => {
        checkLogin();
    }, [])

    return (
        <div>
            {/* -- 顶部导航栏 -- */}
            <header className={styles.navbar}>
                <nav className={styles.container}>
                    <div className={styles.logo}>
                        <img src={LogoSvg} alt="NeuronForce Logo" />
                        <div className={styles.logoText}>{translatedTexts.product_name}</div>
                    </div>
                    <div className={styles.rightBox}>
                        <div
                            className={styles.productIntro}
                            onClick={navigateToProduct}
                        >
                            {translatedTexts.product_intro_btn}
                        </div>
                        <div className={styles.about}>{translatedTexts.about_us}</div>
                        <div
                            className={styles.try}
                            onClick={() => setIsLoginModalOpen(true)}
                        >
                            {translatedTexts.try_button}
                        </div>
                        {/* <!-- 语言选择 --> */}
                        <div className={styles.languageSelector}>
                            <span onClick={() => changeLanguage('en')}>EN</span> |
                            <span onClick={() => changeLanguage('zh')}>中</span>
                        </div>
                        <div className={styles.videoIcon} onClick={navigateToYoutube}>
                            <img src={HeaderVideoIcon} alt="Video Icon" />
                        </div>
                    </div>
                </nav>
            </header>

            {/* <!-- 中间模块 --> */}
            <div className={styles.content}>
                <section className={`${styles.module} ${styles.module1}`}>
                    <div className={styles.container}>
                        <div className={styles.split}>
                            <div className={styles.left}>
                                <h2>{translatedTexts.module1_title}</h2>
                                <p>{translatedTexts.module1_content}</p>
                            </div>
                            <div className={styles.right}>
                                <img src={Module1Img} alt="Module 1 Image" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.module} ${styles.module2}`}>
                    <div className={styles.container}>
                        <div className={styles.split}>
                            <div className={styles.left}>
                                <img src={Module2Img} alt="Module 2 Image" />
                            </div>
                            <div className={styles.right}>
                                <h2>{translatedTexts.module2_title}</h2>
                                <p>{translatedTexts.module2_content}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.module} ${styles.module3}`}>
                    <div className={styles.container}>
                        <div className={styles.split}>
                            <div className={styles.left}>
                                <h2>{translatedTexts.module3_title}</h2>
                                <p>{translatedTexts.module3_content}</p>
                            </div>
                            <div className={styles.right}>
                                <img src={Module3Img} alt="Module 3 Image" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.module} ${styles.module4}`}>
                    <div className={styles.container}>
                        <div className={styles.split}>
                            <div className={styles.left}>
                                <img src={Module4Img} alt="Module 4 Image" />
                            </div>
                            <div className={styles.right}>
                                <h2>{translatedTexts.module4_title}</h2>
                                <p>{translatedTexts.module4_content}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <!-- 底部页脚 --> */}
            <footer className={styles.footer}>
                <div className={styles.footerContent}>
                    <p>© Copyright 2023 NeuronForce. All Rights Reserved.</p>
                    {language === 'zh' &&
                        <span style={{ marginLeft: '10px' }}><a href='https://beian.miit.gov.cn/'>京ICP备2024081874号</a></span>
                    }
                </div>
            </footer>

            {/* <!-- 登录弹窗 --> */}
            <LoginModal
                isLoginModalOpen={isLoginModalOpen}
                setIsLoginModalOpen={() => setIsLoginModalOpen(false)}
            />
        </div>
    );
};

export default Home;