import React, { useState } from 'react';
import { Modal, message, Select, Button } from 'antd';
import styles from './createAgentModal.module.less';
import { createUserWork } from '@/api/userWork';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import DataDefinition from "@/base/DataDefinition";
import { useNavigate } from 'react-router-dom';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/playground/add_preset_question.svg';
import DelPresetQuestionIcon from '@/assets/img/playground/del_preset_question.svg';
import { ChatMessage } from '@/base/ChatMessage';
import mammoth from 'mammoth';
import ChatData from '@/base/PlaygroundData/ChatData';
import { generateMD5 } from '@/utils/utils';
import DocumentData from '@/base/DocumentData';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import ChatAgentIcon from '@/assets/img/chat_component/chat_agent_icon.svg';
import CancelIcon from '@/assets/img/chat_component/cancel_fill_in_blank.svg';
import UploadIcon from '@/assets/img/chat_component/upload_icon.svg';
import { cloneDeep } from 'lodash';
import CloseModalIcon from '@/assets/img/playground/close_modal.svg';

interface FirstStepContentProps {
    agentAvatar: string;
    setAgentAvatar: (value: string) => void;
    agentName: string;
    setAgentName: (value: string) => void;
}

interface SecondStepContentProps {
    agentRole: string;
    setAgentRole: (value: string) => void;
}

interface ThirdStepContentProps {
    agentSkill: string;
    setAgentSkill: (value: string) => void;
}

interface ForthStepContentProps {
    agentRule: string;
    setAgentRule: (value: string) => void;
}

interface FifthStepContentProps {
    documentList: DocumentData[];
    setDocumentList: (value: DocumentData[]) => void;
    currentDocIndex: number;
    setCurrentDocIndex: (value: number) => void;
}

interface SixthStepContentProps {
    presetQuestions: ChatMessage[];
    setPresetQuestions: (value: ChatMessage[]) => void;
}

interface SeventhStepContentProps {
    headerText: string;
    setHeaderText: (value: string) => void;
    ttsVoice: string;
    setTtsVoice: (value: string) => void;
}

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface CreateAgentModalProps {
    isCreateAgentModalOpen: boolean;
    setIsCreateAgentModalOpen: (isOpen: boolean) => void;
}

const CreateAgentModal: React.FC<CreateAgentModalProps> = ({
    isCreateAgentModalOpen,
    setIsCreateAgentModalOpen,
}) => {
    const navigate = useNavigate();
    const { userInfoStore, playgroundStore } = useStores();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [headerText, setHeaderText] = useState<string>('');
    const [agentName, setAgentName] = useState('');
    const [agentAvatar, setAgentAvatar] = useState('');
    const [presetQuestions, setPresetQuestions] = useState<ChatMessage[]>([]);
    const [ttsVoice, setTtsVoice] = useState('BV033_streaming');
    const [isStream, setIsStream] = useState(true);
    const [documentList, setDocumentList] = useState<DocumentData[]>([]);
    const [currentDocIndex, setCurrentDocIndex] = useState(0);
    const [agentRole, setAgentRole] = useState<string>('');
    const [agentSkill, setAgentSkill] = useState<string>('');
    const [agentRule, setAgentRule] = useState<string>('');

    const genNewAgentMd5 = () => {
        if (playgroundStore.userAgentList.length === 0) {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat0`);
        } else {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat${playgroundStore.userAgentList[0].id + 1}`);
        }
    }

    const convertPromptToJson = () => {
        const data = {
            "角色": agentRole,
            "技能": agentSkill,
            "规范": agentRule
        };
        const prompt = JSON.stringify(data);
        return prompt;
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    }

    const createNewAgent = async (md5: string) => {
        if (!agentName) {
            message.error('请输入智能体名称');
            return;
        }
        try {
            const newChatAgent = new ChatData({
                md5: md5,
                userId: userInfoStore.userInfoData.id,
                userName: userInfoStore.userInfoData.name,
                workType: "playground_agent",
                workInfo: new AIChatAgentRegionContent({
                    agentName: agentName,
                    agentAvatar: agentAvatar,
                    systemPrompt: convertPromptToJson(),
                    agentRole: agentRole,
                    agentSkill: agentSkill,
                    agentRule: agentRule,
                    documentList: documentList,
                    currentDocIndex: currentDocIndex,
                    presetMessages: presetQuestions,
                    headerText: headerText,
                    ttsVoice: ttsVoice,
                    isStream: true,
                    agentMode: 'advanced',
                }),
            });

            const createRes: any = await createUserWork(newChatAgent);
            if (createRes.status === 'success') {
                createRes.data = new ChatData(DataDefinition.toCamelCaseObj(createRes.data));
                playgroundStore.setUserAgentList([createRes.data, ...playgroundStore.userAgentList]);
                navigate(`/playground/chat/${createRes.data.md5}`);
                onCancel();
            }

        } catch (error) {
            message.error('发生错误，请重试');
            throw error;
        }
    };

    const onCancel = () => {
        setIsCreateAgentModalOpen(false);
        setCurrentStep(0);
        setAgentName('');
        setAgentAvatar('');
        setAgentRole('');
        setAgentSkill('');
        setAgentRule('');
        setDocumentList([]);
        setCurrentDocIndex(0);
        setPresetQuestions([]);
        setHeaderText('');
        setTtsVoice('BV033_streaming');
    }

    const steps = [
        {
            title: 'First',
            content: <FirstStepContent
                agentAvatar={agentAvatar}
                setAgentAvatar={setAgentAvatar}
                agentName={agentName}
                setAgentName={setAgentName}
            />,
        },
        {
            title: 'Second',
            content: <FifthStepContent
                documentList={documentList}
                setDocumentList={setDocumentList}
                currentDocIndex={currentDocIndex}
                setCurrentDocIndex={setCurrentDocIndex}
            />,
        },
        {
            title: 'Third',
            content: <SecondStepContent
                agentRole={agentRole}
                setAgentRole={setAgentRole}
            />,
        },
        {
            title: 'Forth',
            content: <ThirdStepContent
                agentSkill={agentSkill}
                setAgentSkill={setAgentSkill}
            />,
        },
        {
            title: 'Fifth',
            content: <ForthStepContent
                agentRule={agentRule}
                setAgentRule={setAgentRule}
            />,
        },
        {
            title: 'Sixth',
            content: <SixthStepContent
                presetQuestions={presetQuestions}
                setPresetQuestions={setPresetQuestions}
            />,
        },
        {
            title: 'Seventh',
            content: <SeventhStepContent
                headerText={headerText}
                setHeaderText={setHeaderText}
                ttsVoice={ttsVoice}
                setTtsVoice={setTtsVoice}
            />,
        },
    ];

    return (
        <Modal
            centered
            open={isCreateAgentModalOpen}
            onCancel={onCancel}
            footer={null}
            className={styles.createAgentModal}
            closeIcon={<img src={CloseModalIcon} alt="CloseModalIcon" className={styles.closeModalIcon} />}
        >
            <div className={styles.createAgentContainer}>
                <div className={styles.stepsContentBox}>
                    {steps[currentStep].content}
                </div>
                <div className={styles.stepsAction}>
                    {currentStep > 0 && (
                        <Button onClick={() => prevStep()} className={styles.prevBtn}>
                            上一步
                        </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <Button type="primary" onClick={() => nextStep()} className={styles.nextBtn}>
                            下一步
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="primary" onClick={() => createNewAgent(genNewAgentMd5())} className={styles.nextBtn}>
                            创建
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default observer(CreateAgentModal);

const FirstStepContent: React.FC<FirstStepContentProps> = ({
    agentName, setAgentName,
    agentAvatar, setAgentAvatar
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentAvatarNameBox}>
                <div className={styles.agentAvatarNameTitle}>
                    {`智能体创建 ----> 头像和名称`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentAvatarNameEditBox}>
                    <div className={styles.agentAvatarBox}>
                        {agentAvatar ? <img src={agentAvatar} className={styles.agentAvatar} /> :
                            <div className={styles.agentAvatar}>
                                上传头像
                            </div>
                        }
                        <div className={styles.uploadAvatar}>
                            <UploadMedia
                                successCallBack={(url) => {
                                    setAgentAvatar(url);
                                }}
                                icon={UploadAgentAvatarIcon}
                                accept_str="image/*"
                                dir="avatar"
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    <div className={styles.agentNameInputBox}>
                        <textarea
                            className={styles.agentNameInput}
                            placeholder="请输入智能体名称"
                            value={agentName}
                            onChange={(e) => setAgentName(e.target.value)}
                        />
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentName}
                                setInputMessage={setAgentName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const SecondStepContent: React.FC<SecondStepContentProps> = ({
    agentRole, setAgentRole
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 角色描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入角色描述"
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                    />
                    <div className={styles.btn}>
                        <SpeechToText
                            inputMessage={agentRole}
                            setInputMessage={setAgentRole}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ThirdStepContent: React.FC<ThirdStepContentProps> = ({
    agentSkill, setAgentSkill
}) => {

    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 技能描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入技能描述"
                        value={agentSkill}
                        onChange={(e) => setAgentSkill(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentSkill}
                                setInputMessage={setAgentSkill}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ForthStepContent: React.FC<ForthStepContentProps> = ({
    agentRule, setAgentRule
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 规范描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>

                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入规范描述"
                        value={agentRule}
                        onChange={(e) => setAgentRule(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentRule}
                                setInputMessage={setAgentRule}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FifthStepContent: React.FC<FifthStepContentProps> = ({
    documentList, setDocumentList,
    currentDocIndex, setCurrentDocIndex,
}) => {
    const [isEditingDoc, setIsEditingDoc] = useState(false);
    const propsUpload = {
        isDoc: true,
    };
    const readFileContent = async (file: any, url: string) => {
        // 判断文件类型  
        const fileType = file.type;

        if (fileType === 'text/plain') {
            // 处理 .txt 文件  
            const text = await file.text();
            const newDocument = new DocumentData({
                docContent: text,
                docName: file.name,
                docUrl: url
            });
            setCurrentDocIndex(documentList.length);
            setDocumentList([...documentList, newDocument]);
            setIsEditingDoc(false);
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // 处理 .docx 文件  
            const arrayBuffer = await file.arrayBuffer();
            const { value } = await mammoth.extractRawText({ arrayBuffer });
            const newDocument = new DocumentData({
                docContent: value,
                docName: file.name,
                docUrl: url
            });
            setCurrentDocIndex(documentList.length);
            setDocumentList([...documentList, newDocument]);
            setIsEditingDoc(false);
        } else {
            message.error('文件类型不支持');
        }
    };

    const handleDocItemClick = (index: number) => {
        setCurrentDocIndex(index);
        setIsEditingDoc(false);
    }

    return (
        <div className={styles.stepContent}>
            <div className={styles.uploadDocBox}>
                <div className={styles.uploadDocTitle}>
                    {`智能体创建 ----> 知识库文档`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>

                <div className={styles.docListTab}>
                    <div className={styles.docList}>
                        {documentList.map((item, index) => (
                            <div
                                key={item.id}
                                className={`${styles.docItem} ${currentDocIndex === index ? styles.docItemActive : ''}`}
                                onClick={() => handleDocItemClick(index)}
                            >
                                <div className={styles.docItemName}>{item.docName}</div>
                                <div
                                    className={styles.docItemDel}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const newDocumentList = documentList.filter((doc, i) => i !== index);
                                        setDocumentList(newDocumentList);
                                        if (currentDocIndex === index) {
                                            setCurrentDocIndex(0);
                                        }
                                    }}
                                >
                                    <img src={CancelIcon} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <UploadMedia
                        icon={UploadIcon}
                        successCallBack={(url, file) => {
                            readFileContent(file, url);
                        }}
                        btn_text="上传文档"
                        accept_str=".txt,.docx"
                        dir="docs"
                        isMultiple={false}
                        uploadProps={propsUpload}
                    />
                </div>
                {documentList.length === 0 && (
                    <div className={styles.noDocTip}>
                        请上传知识库文档
                    </div>
                )}
                {documentList.length > 0 && (
                    <>
                        {
                            isEditingDoc &&
                            <div className={styles.docEditAreaBox}>
                                <textarea
                                    className={styles.docEditArea}
                                    placeholder="请输入文档内容"
                                    value={documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                    onChange={(e) => {
                                        const newDocumentList = cloneDeep(documentList);
                                        newDocumentList[currentDocIndex].docContent = e.target.value;
                                        setDocumentList(newDocumentList);
                                    }}
                                />
                                <div className={styles.btn}>
                                    <SpeechToText
                                        inputMessage={documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                        setInputMessage={(value) => {
                                            const newDocumentList = cloneDeep(documentList);
                                            newDocumentList[currentDocIndex].docContent = value;
                                            setDocumentList(newDocumentList);
                                        }}
                                    />
                                </div>
                                <div
                                    className={styles.saveBtn}
                                    onClick={() => {
                                        setIsEditingDoc(false);
                                    }}
                                >
                                    保存
                                </div>
                            </div>
                        }
                        {
                            !isEditingDoc &&
                            <div
                                className={styles.docContentBox}
                            >
                                <div className={styles.docContent}>
                                    {documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                </div>
                                <div
                                    className={styles.editBtn}
                                    onClick={() => setIsEditingDoc(true)}
                                >
                                    编辑
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    )
}

const SixthStepContent: React.FC<SixthStepContentProps> = ({
    presetQuestions, setPresetQuestions
}) => {
    const handleAddPresetQuestion = () => {
        const newPresetQuestion = new ChatMessage({});
        setPresetQuestions([...presetQuestions, newPresetQuestion]);
    }

    const handleDelPresetQuestion = (id: number) => {
        const newPresetQuestions = presetQuestions.filter((question) => question.id !== id);
        setPresetQuestions(newPresetQuestions);
    }
    return (
        <div className={styles.stepContent}>
            <div className={styles.addPresetQuestionsBox}>
                <div className={styles.addPresetQuestionsTitle}>
                    {`智能体创建 ----> 预设问题`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.addPresetQuestionsBtnBox}>
                    <div
                        className={styles.addPresetQuestionsBtn}
                        onClick={handleAddPresetQuestion}
                    >
                        <img src={AddPresetQuestionIcon} />
                        添加问题
                    </div>
                </div>
                <div className={styles.presetQuestionsList}>
                    {presetQuestions.map((question, index) => (
                        <div key={question.id} className={styles.presetQuestion}>
                            <div className={styles.presetQuestionTitle}>预设问题{index + 1}</div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    className={styles.presetQuestionInput}
                                    placeholder="请输入问题"
                                    value={question.rawContent}
                                    onChange={(e) => {
                                        question.rawContent = e.target.value;
                                        setPresetQuestions([...presetQuestions]);
                                    }}
                                />
                                 <div className={styles.microphoneBtn}>
                                    <SpeechToText
                                        inputMessage={question.rawContent}
                                        setInputMessage={(value) => {
                                            question.rawContent = value;
                                            setPresetQuestions([...presetQuestions]);
                                        }}
                                    />
                                </div>
                                <div
                                    className={styles.delPresetQuestion}
                                    onClick={() => handleDelPresetQuestion(question.id)}
                                >
                                    <img src={DelPresetQuestionIcon} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const SeventhStepContent: React.FC<SeventhStepContentProps> = ({
    headerText, setHeaderText,
    ttsVoice, setTtsVoice
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.welcomeTextBox}>
                <div className={styles.welcomeTextTitle}>
                    {`智能体创建 ----> 欢迎语和音色`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.welcomeAndVoiceBox}>
                    <div className={styles.welcomeTextInputBox}>
                        <textarea
                            className={styles.welcomeTextInput}
                            placeholder="请输入欢迎语"
                            value={headerText}
                            onChange={(e) => setHeaderText(e.target.value)}
                        />
                        <div className={styles.btnArea}>
                            <div className={styles.btn}>
                                <SpeechToText
                                    inputMessage={headerText}
                                    setInputMessage={setHeaderText}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.voiceSelectArea}>
                        <div className={styles.voiceSelectAreaTitle}>选择音色</div>
                        <Select
                            value={ttsVoice}
                            onChange={(value) => setTtsVoice(value)}
                            className={styles.voiceSelect}
                        >
                            {voiceList.map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

            </div>
        </div >
    )
}