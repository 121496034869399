import service from "@/services/axios";

const ApiPath = {
    GET_INSTITUTION: (id: number) => `/institution/${id}`,
    ENROLL_STUDENT: '/enroll_student',
    DELIST_STUDENT: '/delist_student',
    GET_CLASS: (id: number) => `/class/${id}`,
    GET_INSTITUTION_BY_ADMIN_ID: (id: number) => `/institution_by_admin_id/${id}`,
}
const toQueryString = (params: Record<string, any>) => {  
    return new URLSearchParams(params).toString();  
}  

export const getInstitution = (id: number): Promise<any> => {
    return service.get(ApiPath.GET_INSTITUTION(id));
}

export const enrollStudent = (params: Record<string, any>): Promise<any> => {  
    const queryString = toQueryString(params);  
    // Combine the API path and the query string  
    return service.put(`${ApiPath.ENROLL_STUDENT}?${queryString}`);  
} 

export const delistStudent = (classId: number, studentId: number): Promise<any> => {
    return service.get(ApiPath.DELIST_STUDENT, { params: { class_id: classId, student_id: studentId } });
}

export const getClass = (id: number): Promise<any> => {
    return service.get(ApiPath.GET_CLASS(id));
}

export const getInstitutionByAdminId = (id: number): Promise<any> => {
    return service.get(ApiPath.GET_INSTITUTION_BY_ADMIN_ID(id));
}