import React, { useEffect, useState } from 'react';
import styles from "./studentManagement.module.less";
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { enrollStudent, delistStudent, getClass, getInstitutionByAdminId } from '@/api/studentManagement';
import { Button, Table, Modal, Form, Input } from 'antd';
import sha256 from 'crypto-js/sha256';

const StudentManagement: React.FC = () => {
    const { userInfoStore } = useStores();
    const [students, setStudents] = useState([]);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [classId, setClassId] = useState(0);
    const [institutionName, setInstitutionName] = useState("");
    const [editingStudent, setEditingStudent] = useState(null);
    const [form] = Form.useForm();

    const getInstitutionInfo = async (adminId: number) => {
        try {
            const res = await getInstitutionByAdminId(adminId);
            console.log(res);
            if (res.status === "success") {
                getClassInfo(res.data.classes[0][0]);
                setClassId(res.data.classes[0][0]);
                setInstitutionName(res.data.name);
            } else {
                console.error('API Error:', res.data);
            }
        } catch (error) {
            console.error('API Error:', error);
        }
    }

    const getClassInfo = async (classId: number) => {
        try {
            const res = await getClass(classId);
            if (res.status === 'success') {
                // 转换 students 数据结构  
                const formattedStudents = res.data.students.map((student: any) => ({
                    id: student[0],     // 学生 ID  
                    name: student[1]    // 学生姓名  
                }));
                setStudents(formattedStudents);
            }
        } catch (error) {
            console.error('API Error:', error);
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0) {
            return;
        }
        getInstitutionInfo(userInfoStore.userInfoData.id);
    }, [userInfoStore.userInfoData.id]);

    // 显示添加学生模态框
    const showAddModal = () => {
        setIsAddModalVisible(true);
        setEditingStudent(null); // 重置编辑状态  
        //设置默认密码为111111
        form.setFieldsValue({ default_pwd: '111111' });
    };

    // 处理添加学生提交
    const handleAddStudent = async () => {
        try {
            const values = await form.validateFields();
            const newStudent = {
                student_name: values.student_name,
                default_pwd: sha256(values.default_pwd).toString(),
                class_id: classId,
                institution_name: institutionName
            };
            const res: any = await enrollStudent(newStudent);
            if (res.status === 'success') {
                getClassInfo(classId);
                setIsAddModalVisible(false);
                form.resetFields();
            } else {
                console.error('API Error:', res.data);
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    const showEditModal = (student: any) => {
        setEditingStudent(student);
        form.setFieldsValue({ student_name: student.name, default_pwd: '' }); // 填充表单  
        setIsAddModalVisible(true);
    };

    // 处理删除学生
    const handleDeleteStudent = (classId: number, studentId: number) => {
        Modal.confirm({
            title: '删除学生',
            content: '确定删除该学生吗？',
            centered: true,
            onOk: async () => {
                try {
                    const res = await delistStudent(classId, studentId);
                    if (res.status === 'success') {
                        getClassInfo(classId);
                    } else {
                        console.error('API Error:', res.data);
                    }
                } catch (error) {
                    console.error('API Error:', error);
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    // 表格列配置
    const columns = [
        {
            title: '学生姓名',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => {
                // 在渲染时处理学生姓名，去掉学校名称  
                return text.replace(institutionName, '').trim();
            },
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => (
                <>
                    {/* <Button type="primary" onClick={() => showEditModal(record)}>
                        编辑
                    </Button> */}
                    <Button type="default" onClick={() => handleDeleteStudent(classId, record.id)} style={{ marginLeft: 8 }}>
                        删除
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className={styles.studentManagement}>
            <Button type="primary" onClick={showAddModal}>
                添加学生
            </Button>
            <Table dataSource={students} columns={columns} rowKey="id" style={{ marginTop: 20 }} />
            <Modal
                title={editingStudent ? "编辑学生" : "添加学生"}
                open={isAddModalVisible}
                onOk={handleAddStudent}
                onCancel={() => {
                    setIsAddModalVisible(false);
                    setIsEditModalVisible(false);
                    form.resetFields();
                }}
                cancelText="取消"
                okText="确定"
                centered
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="student_name"
                        label="学生姓名"
                        rules={[{ required: true, message: '请输入学生姓名' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="default_pwd"
                        label="默认密码"
                        rules={[{ required: true, message: '请输入默认密码' }, { min: 6, message: '密码最少为6个字符' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default observer(StudentManagement);