import service from "@/services/axios";

const ApiPath = {
    GEN_ORDER_AND_QRCODE: '/gen_order_and_qrcode',
    GET_PAYMENT: (paymentId: number) => `/payment/${paymentId}`,
}

export const genOrderAndQRcode = (data: any): Promise<any> => {
    return service.post(ApiPath.GEN_ORDER_AND_QRCODE, data);
}

export const getPayment = (paymentId: number): Promise<any> => {
    return service.get(ApiPath.GET_PAYMENT(paymentId));
}