import DataDefinition from "../DataDefinition";
import AIChatAgentRegionContent from "../ElementData/AIChatAgentRegionContent";

class ChatData extends DataDefinition {
    id: number;
    md5: string;
    userId: number;
    userName: string;
    workType: string;
    workInfo: AIChatAgentRegionContent;
    createTime: number;

    constructor({
        id = 0,
        md5 = "",
        userId = 0,
        userName = "",
        workType = "",
        workInfo = new AIChatAgentRegionContent(),
        createTime = 0
    }: Partial<ChatData> = {}) {
        super();
        this.allowedFields = ['id', 'md5', 'userId', 'userName', 'workType', 'workInfo', 'createTime'];
        this.id = id;
        this.md5 = md5;
        this.userId = userId;
        this.userName = userName;
        this.workType = workType;
        this.workInfo = new AIChatAgentRegionContent(workInfo);
        this.createTime = createTime;
    }
}

export default ChatData;