import styles from './shareMultiImages.module.less';
import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import MarkdownWithHighlight from '@/components/MarkdownWithHighlight/MarkdownWithHighlight';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';
import PlayAudioIcon from '@/assets/img/chat_component/play_input_message.svg';
import GenAudioLoading from '@/assets/img/chat_component/gen_audio_loading.svg';
import MultimediaAPI from '@/api/multimedia';
import { message } from 'antd';
import BookIcon from '@/assets/img/share_page/book_icon.svg';
import PencilIcon from '@/assets/img/share_page/pencil_icon.svg';
import EductionIcon from '@/assets/img/share_page/education_icon.svg';
import MultiImagesInfo from '@/base/ShareData/MultiImagesInfo';

interface ShareMultiImagesProps {
    multiImagesInfo: MultiImagesInfo;
}

type AudioState = {
    generating: boolean;
    audioUrl: string;
};

const ShareMultiImages: React.FC<ShareMultiImagesProps> = ({ multiImagesInfo }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const ttsAPI = new MultimediaAPI();
    const [audioStates, setAudioStates] = useState<{ [key: string]: AudioState }>({});
    const [currentPlayingId, setCurrentPlayingId] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    // 移动端内容配置
    const mobileContents = [
        { id: '1', imgUrl: multiImagesInfo.imgUrl1, content: multiImagesInfo.content1, voiceUrl: multiImagesInfo.contentVoiceUrl1 },
        { id: '2', imgUrl: multiImagesInfo.imgUrl2, content: multiImagesInfo.content2, voiceUrl: multiImagesInfo.contentVoiceUrl2 },
        { id: '3', imgUrl: multiImagesInfo.imgUrl3, content: multiImagesInfo.content3, voiceUrl: multiImagesInfo.contentVoiceUrl3 },
        { id: '4', imgUrl: multiImagesInfo.imgUrl4, content: multiImagesInfo.content4, voiceUrl: multiImagesInfo.contentVoiceUrl4 },
        { id: '5', imgUrl: multiImagesInfo.imgUrl5, content: multiImagesInfo.content5, voiceUrl: multiImagesInfo.contentVoiceUrl5 },
        { id: '6', imgUrl: multiImagesInfo.imgUrl6, content: multiImagesInfo.content6, voiceUrl: multiImagesInfo.contentVoiceUrl6 },
    ];

    // 清理音频资源
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
            }
        };
    }, []);

    // 统一媒体渲染方法
    const renderMedia = (url: string) => {
        return url.endsWith('mp4') ? (
            <video src={url} controls className={styles.image} />
        ) : (
            <img src={url} className={styles.image} alt="content" />
        );
    };

    // 生成语音
    const genVoice = async (id: string, text: string) => {
        try {
            setAudioStates(prev => ({
                ...prev,
                [id]: { ...prev[id], generating: true }
            }));

            const res = await ttsAPI.genVoiceAndUpload(
                { text, voice_type: 'BV700_streaming' },
                'tts'
            );

            setAudioStates(prev => ({
                ...prev,
                [id]: {
                    generating: false,
                    audioUrl: res.file_url
                }
            }));

            return res.file_url;
        } catch (error) {
            message.error('语音生成失败');
            setAudioStates(prev => ({
                ...prev,
                [id]: { ...prev[id], generating: false }
            }));
            return null;
        }
    };

    // 点击播放按钮
    const handlePlay = async (id: string, content: string, voiceUrl?: string) => {
        // 停止当前播放
        if (audioRef.current) {
            audioRef.current.pause();
            setCurrentPlayingId(null);
        }

        // 获取当前音频状态
        const currentState = audioStates[id] || {
            generating: false,
            audioUrl: voiceUrl || ''
        };

        // 处理生成状态
        if (currentState.generating) return;

        // 需要生成语音
        if (!currentState.audioUrl) {
            const generatedUrl = await genVoice(id, content);
            if (!generatedUrl) return;
            currentState.audioUrl = generatedUrl;
        }

        // 创建新音频实例
        const newAudio = new Audio(currentState.audioUrl);
        newAudio.addEventListener('ended', () => setCurrentPlayingId(null));

        try {
            await newAudio.play();
            audioRef.current = newAudio;
            setCurrentPlayingId(id);
        } catch (error) {
            console.error('播放失败:', error);
        }
    };

    // 获取图标状态
    const getIcon = (id: string) => {
        if (currentPlayingId === id) return AudioPlayingIcon;
        if (audioStates[id]?.generating) return GenAudioLoading;
        return PlayAudioIcon;
    };

    return (
        <div className={isMobile ? styles.mobilePage : styles.pcPage}>
            {/* PC端布局 */}
            {!isMobile && (
                <div className={styles.tabletDesktopLayout}>
                    <div className={styles.containerBox}>
                        <div className={styles.title}>{multiImagesInfo.title}</div>

                        {mobileContents.map((section) => (
                            <React.Fragment key={section.id}>
                                <div className={styles.imgBox}>
                                    {renderMedia(section.imgUrl)}
                                </div>
                                <div className={styles.textContentBox}>
                                    <div
                                        className={styles.textContent}
                                        style={{
                                            backgroundColor: multiImagesInfo.contentBackgroundColor,
                                            ...(multiImagesInfo.contentTextAlign !== "" && {
                                                textAlign: multiImagesInfo.contentTextAlign as any
                                            })
                                        }}
                                    >
                                        <MarkdownWithHighlight content={section.content} />
                                    </div>
                                    <div className={styles.playContentBtn}>
                                        <img
                                            src={getIcon(section.id)}
                                            alt="audio"
                                            onClick={() => handlePlay(
                                                section.id,
                                                section.content,
                                                section.voiceUrl
                                            )}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}

            {/* 移动端布局 */}
            {isMobile && (
                <div className={styles.mobileLayout}>
                    <div className={styles.containerBox}>
                        <div className={styles.title}>{multiImagesInfo.title}</div>

                        {mobileContents.map((section) => (
                            <React.Fragment key={section.id}>
                                <div className={styles.imgBox}>
                                    {renderMedia(section.imgUrl)}
                                </div>
                                <div className={styles.textContentBox}>
                                    <div
                                        className={styles.textContent}
                                        style={{
                                            backgroundColor: multiImagesInfo.contentBackgroundColor,
                                            ...(multiImagesInfo.contentTextAlign !== "" && {
                                                textAlign: multiImagesInfo.contentTextAlign as any
                                            })
                                        }}
                                    >
                                        <MarkdownWithHighlight content={section.content} />
                                    </div>
                                    <div className={styles.playContentBtn}>
                                        <img
                                            src={getIcon(section.id)}
                                            alt="audio"
                                            onClick={() => handlePlay(
                                                section.id,
                                                section.content,
                                                section.voiceUrl
                                            )}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}

                        <div className={styles.bookIcon}><img src={BookIcon} alt="book" /></div>
                        <div className={styles.pencilIcon}><img src={PencilIcon} alt="pencil" /></div>
                        <div className={styles.educationIcon}><img src={EductionIcon} alt="education" /></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShareMultiImages;