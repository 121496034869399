import React from 'react';
import styles from './loginForInstitution.module.less';
import { useState, useEffect } from "react";
import { Button, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import service from "@/services/axios";
import qs from 'qs';
import sha256 from 'crypto-js/sha256';
import RobotLogo from "@/assets/img/robot_logo.svg";

const { Option } = Select;

const LoginForInstitution: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utm = searchParams.get("utm"); // 获取 utm 参数的值
    const [loginType, setLoginType] = useState("admin");
    const [currentMode, setCurrentMode] = useState("login");
    const [adminName, setAdminName] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [studentName, setStudentName] = useState("");
    const [studentPassword, setStudentPassword] = useState("");
    const [role, setRole] = useState(2);
    const [adminNameError, setAdminNameError] = useState("");
    const [adminPasswordError, setAdminPasswordError] = useState("");
    const [schoolNameError, setSchoolNameError] = useState("");
    const [studentNameError, setStudentNameError] = useState("");
    const [studentPasswordError, setStudentPasswordError] = useState("");

    const navigate = useNavigate();
    const validateAdminName = () => {
        if (!adminName.trim()) {
            setAdminNameError("机构名不能为空");
            return false;
        }
        if (adminName.includes(" ")) {
            setAdminNameError("机构名不能包含空格");
            return false;
        }
        setAdminNameError("");
        return true;
    };

    const validateAdminPassword = () => {
        if (!adminPassword.trim()) {
            setAdminPasswordError("密码不能为空");
            return false;
        }
        if (adminPassword.includes(" ")) {
            setAdminPasswordError("密码不能包含空格");
            return false;
        }
        if (adminPassword.length < 5) {
            setAdminPasswordError("密码长度不能少于5个字符");
            return false;
        }
        if (adminPassword.length > 24) {
            setAdminPasswordError("密码长度不能超过24个字符");
            return false;
        }
        setAdminPasswordError("");
        return true;
    };

    const validateSchoolName = () => {
        if (!schoolName.trim()) {
            setSchoolNameError("学校不能为空");
            return false;
        }
        if (schoolName.includes(" ")) {
            setSchoolNameError("姓名不能包含空格");
            return false;
        }
        setSchoolNameError("");
        return true;
    };

    const validateStudentName = () => {
        if (!studentName.trim()) {
            setStudentNameError("姓名不能为空");
            return false;
        }
        if (studentName.includes(" ")) {
            setStudentNameError("姓名不能包含空格");
            return false;
        }
        setStudentNameError("");
        return true;
    };

    const validateStudentPassword = () => {
        if (!studentPassword.trim()) {
            setStudentPasswordError("密码不能为空");
            return false;
        }
        if (studentPassword.includes(" ")) {
            setStudentPasswordError("密码不能包含空格");
            return false;
        }
        if (studentPassword.length < 5) {
            setStudentPasswordError("密码长度不能少于5个字符");
            return false;
        }
        if (studentPassword.length > 24) {
            setStudentPasswordError("密码长度不能超过24个字符");
            return false;
        }
        setStudentPasswordError("");
        return true;
    };

    const onAdminLogin = async () => {
        const isAdminNameValid = validateAdminName();
        const isAdminPasswordValid = validateAdminPassword();

        if (!isAdminNameValid || !isAdminPasswordValid) {
            return;
        }

        const values = { username: adminName + '默认管理员', password: sha256(adminPassword).toString() };
        const data = qs.stringify(values);
        try {
            const res: any = await service.post("/login", data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
            if (res.status === 'success') {
                message.success("登录成功", 1.5);
                localStorage.setItem("token", res.data.access_token);
                navigate("/");
            } else {
                message.error(res.data);
            }
        } catch (error) {
            console.warn(error);
            message.error("登录异常，请稍后再试");
        }
    };


    const onRegister = () => {
        const isAdminNameValid = validateAdminName();
        const isAdminPasswordValid = validateAdminPassword();

        if (!isAdminNameValid || !isAdminPasswordValid) {
            return;
        }
        const secureHash = sha256(adminPassword).toString();
        const values = {
            real_username: adminName,
            secure_hash: secureHash,
            institution_type: "school",
            utm: utm ? utm : ""
        };
        console.log(values);

        //注册
        service.post("/institution", values).then((res: any) => {
            if (res.status === "success") {
                message.success("注册成功");
                setCurrentMode("login");
            } else {
                message.error(res.data);
            }
        });
    };

    const onStudentLogin = async () => {
        const isStudentNameValid = validateStudentName();
        const isStudentPasswordValid = validateStudentPassword();

        if (!isStudentNameValid || !isStudentPasswordValid) {
            return;
        }

        const values = { username: schoolName + studentName, password: sha256(studentPassword).toString() };
        const data = qs.stringify(values);
        try {
            const res: any = await service.post("/login", data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
            if (res.status === 'success') {
                message.success("登录成功", 1.5);
                localStorage.setItem("token", res.data.access_token);
                navigate("/");
            } else {
                message.error(res.data);
            }
        } catch (error) {
            console.warn(error);
            message.error("登录异常，请稍后再试");
        }
    };

    const onChangeMode = (mode: string) => {
        setAdminName("");
        setAdminPassword("");
        setAdminNameError("");
        setAdminPasswordError("");
        setCurrentMode(mode);
    };

    useEffect(() => {
        localStorage.removeItem('currentLevel');
        localStorage.removeItem('currentCourseId');
        localStorage.removeItem('currentCourseName');
        localStorage.removeItem('currentCourse');
    }, [])
    return (
        <div className={styles.loginV2Page}>
            <div className={styles.mainBox}>
                <div className={styles.topArea}>
                    <div className={styles.radishIcon}>
                        <img src={RobotLogo} alt="hello" />
                    </div>
                    <div className={styles.title}>欢迎来到艾宝AI 机构学校登录</div>
                    <div className={styles.subTitle}>在这里我们将一起掌握并了解AI的知识</div>
                    <div className={styles.loginType}>
                        <span
                            className={`${loginType === "admin" ? styles.active : ""} ${styles.loginTypeItem}`}
                            onClick={() => setLoginType("admin")}
                        >
                            管理员登录
                        </span>
                        <span
                            className={`${loginType === "student" ? styles.active : ""} ${styles.loginTypeItem}`}
                            onClick={() => setLoginType("student")}
                        >
                            学生登录
                        </span>
                    </div>
                </div>
                {loginType === "admin" && (
                    <div className={styles.accountLogin}>
                        {
                            currentMode === "login" && (
                                <div className={styles.loginBox}>
                                    <div className={styles.accountText}>机构名称</div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="text"
                                            placeholder="请输入机构名称"
                                            className={styles.input}
                                            value={adminName}
                                            onChange={(e) => setAdminName(e.target.value)}
                                            onBlur={validateAdminName}
                                        />
                                        {adminNameError && <div className={styles.errorText}>{adminNameError}</div>}
                                    </div>
                                    <div className={styles.passwordText}>
                                        <span>密码</span>
                                        {/* <span className={styles.forget}>忘记密码?</span> */}
                                    </div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="password"
                                            placeholder="请输入密码"
                                            className={styles.input}
                                            value={adminPassword}
                                            onChange={(e) => setAdminPassword(e.target.value)}
                                            onBlur={validateAdminPassword}
                                        />
                                        {adminPasswordError && <div className={styles.errorText}>{adminPasswordError}</div>}
                                    </div>
                                    <Button className={styles.loginBtn} onClick={onAdminLogin}>登录</Button>
                                    <div className={styles.goToRegister}>
                                        还没有账号？点击这里 <span onClick={() => onChangeMode("register")}>注册</span>
                                    </div>
                                    <div className={styles.agreement}>
                                        登录视为您已阅读并同意艾宝AI <span>用户使用条款</span> 和 <span>隐私政策</span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            currentMode === "register" && (
                                <div className={styles.registerBox}>
                                    <div className={styles.itemText}>机构名称</div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="text"
                                            placeholder="请输入机构名称"
                                            className={styles.input}
                                            value={adminName}
                                            onChange={(e) => setAdminName(e.target.value)}
                                            onBlur={validateAdminName}
                                        />
                                        {adminNameError && <div className={styles.errorText}>{adminNameError}</div>}
                                    </div>
                                    <div className={styles.itemText}>密码</div>
                                    <div className={styles.inputBox}>
                                        <input
                                            type="password"
                                            placeholder="请输入密码"
                                            className={styles.input}
                                            value={adminPassword}
                                            onChange={(e) => setAdminPassword(e.target.value)}
                                            onBlur={validateAdminPassword}
                                        />
                                        {adminPasswordError && <div className={styles.errorText}>{adminPasswordError}</div>}
                                    </div>
                                    {/* {import.meta.env.VITE_ENV_NAME === "DEVELOPMENT" && <div className={styles.itemText}>选择角色</div>}
                                {import.meta.env.VITE_ENV_NAME === "DEVELOPMENT" && <Select
                                    placeholder="请选择角色"
                                    className={styles.select}
                                    value={role}
                                    onChange={(value) => setRole(value)}
                                >
                                    <Option value={1}>教师</Option>
                                    <Option value={2}>学生</Option>
                                </Select>} */}
                                    <Button className={styles.registerBtn} onClick={onRegister}>注册</Button>
                                    <div className={styles.goToLogin}>
                                        已有账号？点击这里 <span onClick={() => onChangeMode("login")}>登录</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )}
                {loginType === "student" && (
                    <div className={styles.accountLogin}>
                        <div className={styles.loginBox}>
                            <div className={styles.accountText}>学校</div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder="请输入学校"
                                    className={styles.input}
                                    value={schoolName}
                                    onChange={(e) => setSchoolName(e.target.value)}
                                    onBlur={validateSchoolName}
                                />
                                {schoolNameError && <div className={styles.errorText}>{schoolNameError}</div>}
                            </div>
                            <div className={styles.accountText}>姓名</div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder="请输入姓名"
                                    className={styles.input}
                                    value={studentName}
                                    onChange={(e) => setStudentName(e.target.value)}
                                    onBlur={validateStudentName}
                                />
                                {studentNameError && <div className={styles.errorText}>{studentNameError}</div>}
                            </div>
                            <div className={styles.passwordText}>
                                <span>密码</span>
                                {/* <span className={styles.forget}>忘记密码?</span> */}
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    type="password"
                                    placeholder="请输入密码"
                                    className={styles.input}
                                    value={studentPassword}
                                    onChange={(e) => setStudentPassword(e.target.value)}
                                    onBlur={validateStudentPassword}
                                />
                                {studentPasswordError && <div className={styles.errorText}>{studentPasswordError}</div>}
                            </div>
                            <Button className={styles.loginBtn} onClick={onStudentLogin}>登录</Button>
                            <div className={styles.agreement}>
                                登录视为您已阅读并同意艾宝AI <span>用户使用条款</span> 和 <span>隐私政策</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoginForInstitution;