import DataDefinition from "../DataDefinition";

class MultiImagesInfo extends DataDefinition {
    title: string;
    content1: string;
    content2: string;
    content3: string;
    content4: string;
    content5: string;
    content6: string;
    imgUrl1: string;
    imgUrl2: string;
    imgUrl3: string;
    imgUrl4: string;
    imgUrl5: string;
    imgUrl6: string;
    contentVoiceUrl1: string;
    contentVoiceUrl2: string;
    contentVoiceUrl3: string;
    contentVoiceUrl4: string;
    contentVoiceUrl5: string;
    contentVoiceUrl6: string;
    shareDescription: string;
    contentBackgroundColor: string;
    contentTextAlign: string;

    constructor({
        title = "",
        content1 = "",
        content2 = "",
        content3 = "",
        content4 = "",
        content5 = "",
        content6 = "",
        imgUrl1 = "",
        imgUrl2 = "",
        imgUrl3 = "",
        imgUrl4 = "",
        imgUrl5 = "",
        imgUrl6 = "",
        contentVoiceUrl1 = "",
        contentVoiceUrl2 = "",
        contentVoiceUrl3 = "",
        contentVoiceUrl4 = "",
        contentVoiceUrl5 = "",
        contentVoiceUrl6 = "",
        shareDescription = "",
        contentBackgroundColor = "",
        contentTextAlign = ""
    }: Partial<MultiImagesInfo> = {}) {
        super();
        this.allowedFields = ['title', 'content1', 'content2', 'content3', 'content4', 'content5', 'content6',
            'imgUrl1', 'imgUrl2', 'imgUrl3', 'imgUrl4', 'imgUrl5', 'imgUrl6', 'contentVoiceUrl1', 'contentVoiceUrl2',
            'contentVoiceUrl3', 'contentVoiceUrl4', 'contentVoiceUrl5', 'contentVoiceUrl6', 'shareDescription',
            'contentBackgroundColor', 'contentTextAlign'];
        this.title = title;
        this.content1 = content1;
        this.content2 = content2;
        this.content3 = content3;
        this.content4 = content4;
        this.content5 = content5;
        this.content6 = content6;
        this.imgUrl1 = imgUrl1;
        this.imgUrl2 = imgUrl2;
        this.imgUrl3 = imgUrl3;
        this.imgUrl4 = imgUrl4;
        this.imgUrl5 = imgUrl5;
        this.imgUrl6 = imgUrl6;
        this.contentVoiceUrl1 = contentVoiceUrl1;
        this.contentVoiceUrl2 = contentVoiceUrl2;
        this.contentVoiceUrl3 = contentVoiceUrl3;
        this.contentVoiceUrl4 = contentVoiceUrl4;
        this.contentVoiceUrl5 = contentVoiceUrl5;
        this.contentVoiceUrl6 = contentVoiceUrl6;
        this.shareDescription = shareDescription;
        this.contentBackgroundColor = contentBackgroundColor;
        this.contentTextAlign = contentTextAlign;
    }
}

export default MultiImagesInfo;