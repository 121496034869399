import React, { useEffect, useState, useRef } from 'react';
import styles from './productPurchase.module.less';
import service from '@/services/axios';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { message } from 'antd';
import MobileVerificationModal from './MobileVerificationModal/MobileVerificationModal';
import Product1HeadImg from '@/assets/img/product_details/product_1_head.jpg';
import ProductV2BodyImg from '@/assets/img/product_details/product_v2_body.jpg';
import { genOrderAndQRcode, getPayment } from '@/api/recharge';
import { useNavigate } from 'react-router-dom';
import HeadRobotImg from '@/assets/img/product_details/head_robot.svg';
import HeadWelcomeTextImg from '@/assets/img/product_details/head_welcome_text.svg';

const ProductPurchase: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [isBuying, setIsBuying] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [productDetails, setProductDetails] = useState<any | null>(null);
    const [paymentQRCode, setPaymentQRCode] = useState("暂无");
    const [countdown, setCountdown] = useState(0);
    const pollingInterval = useRef<number | null>(null);
    const timerRef = useRef<number | null>(null);
    const [paymentStatus, setPaymentStatus] = useState<'no_qr_code' | 'show_qr_code' | 'payment_success' | 'qr_code_expired'>('no_qr_code');
    const [isGenOrderLoading, setIsGenOrderLoading] = useState(false);

    //购买
    const handleBuy = async () => {
        if (!productDetails) {
            message.error('未获取到产品详情');
            console.error('未获取到产品详情');
            return;
        }
        if (!phoneNumber) {
            setIsModalVisible(true);
            return;
        }
        if (isBuying) {
            return;
        }
        const data = {
            id: 0,
            user_id: userInfoStore.userInfoData.id,
            product_id: parseInt(productDetails.id),
            alternative_id: `${phoneNumber}`,
            id_type: "phone",
            total_amount: productDetails.price / 1000,
            original_price: productDetails.price / 1000,
            price: productDetails.price / 1000,
            currency: productDetails.currency,
            status: 1,
            payment_method: "wechat_qrcode",
            openid: "",
            extras: {
                ...(productDetails.extras?.utm !== undefined ? { utm: productDetails.extras.utm } : {})
            }
        }
        setIsBuying(true);
        setIsGenOrderLoading(true);
        try {
            const res: any = await genOrderAndQRcode(data);
            if (res.status === 'success') {
                setPaymentQRCode(res.data.code_url);
                setPaymentStatus('show_qr_code');
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                }
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                startPolling(res.data.payment_id);
                setCountdown(300); // 5 分钟（300 秒）倒计时
            }
        } catch (error) {
            message.error("生成订单失败，请重试")
            console.error('生成订单和二维码失败:', error);
        } finally {
            setIsBuying(false);
            setIsGenOrderLoading(false);
        }
    }

    const startPolling = (paymentId: number) => {
        pollingInterval.current = setInterval(async () => {
            try {
                const res = await getPayment(paymentId);
                if (res.status === 'success') {
                    if (res.data.status === 1) {
                        if (pollingInterval.current) {
                            clearInterval(pollingInterval.current);
                        }
                        pollingInterval.current = null;
                        message.success('支付成功');
                        setPaymentStatus('payment_success');
                        setPaymentQRCode("支付完成");
                        navigate('/pay_success');

                        if (timerRef.current) {
                            clearTimeout(timerRef.current);
                        }
                    }
                }
            } catch (error) {
                console.error('查询订单状态失败:', error);
            }
        }, 2000);
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current as number);
            pollingInterval.current = null;
        }
    };

    useEffect(() => {
        if (countdown > 0) {
            timerRef.current = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
            };
        } else if (countdown === 0 && paymentStatus === 'show_qr_code') {
            setPaymentStatus('qr_code_expired');
            setPaymentQRCode("二维码已过期");
            message.error('二维码已过期，请重新生成订单');
        }
    }, [countdown, paymentStatus]);

    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, []);

    const getProductDetails = async (productId: number) => {
        if (!productId) {
            console.error('未获取到产品id');
            return;
        }
        try {
            const res: any = await service.get(`/product/${productId}`);
            if (res.status === 'success') {
                setProductDetails(res.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getProductDetails(8);
    }, []);

    return (
        <>
            {productDetails && productDetails.stock !== 0 &&
                <div className={styles.productPurchasePage}>
                    <div className={styles.productDetailsPage}>
                        <div className={styles.productHead}>
                            {/* <img src={Product1HeadImg} alt="product" className={styles.productHeadImg} /> */}
                            <div className={styles.headContent}>
                                <div className={styles.title}>
                                    <img src={HeadWelcomeTextImg} alt="product" className={styles.welcomeTextImg} />
                                </div>
                                <div className={styles.subTitle}>在这里你将经历一场前所未有的AI冒险之旅</div>
                                <div className={styles.introduce}>
                                    艾宝AI的《给孩子们的AI课》是一款由硅谷AI博士团队强力打造的，专门为5-10岁孩子设计的学习AGI的课程产品，它结合了最新的人工智能技术，是一把打开未来的钥匙
                                </div>
                            </div>
                            <img src={HeadRobotImg} alt="product" className={styles.productHeadImg} />
                        </div>
                        <div className={styles.productPrice}>
                            <div className={styles.leftBox}>
                                <div className={styles.title1}>
                                    未来已来，紧跟时代
                                </div>
                                <div className={styles.title2}>
                                    国家教委11月刚发布<span className={styles.light}>《关于加强中小学人工智能教育的通知》</span><br />
                                    这是未来中国教育最大的一个方向上的转变
                                </div>
                            </div>
                            <div className={styles.rightBox}>
                                <div className={styles.priceTitle}>优惠价</div>
                                <div className={styles.price}>
                                    {productDetails ? `¥ ${productDetails.price / 1000}` : '¥ ***'}
                                </div>
                                <div className={styles.priceOriginal}>
                                    原价 <del>¥ 1999</del>
                                </div>
                            </div>
                        </div>
                        <div className={styles.productBody} >
                            <img src={ProductV2BodyImg} alt="product" className={styles.productBodyImg} />
                        </div>
                        <MobileVerificationModal
                            isModalVisible={isModalVisible}
                            setIsModalVisible={setIsModalVisible}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            verifySuccessCallback={handleBuy}
                            isGenOrderLoading={isGenOrderLoading}
                            paymentQRCode={paymentQRCode}
                            qrCodeCountdown={countdown}
                            paymentStatus={paymentStatus}
                            setPaymentQRCode={setPaymentQRCode}
                        />
                    </div>
                    <div className={styles.buyBtnBox}>
                        <div
                            className={styles.buyBtn}
                            onClick={() => setIsModalVisible(true)}
                        >
                            立即购买 ¥ {productDetails ? productDetails.price / 1000 : '***'}
                        </div>
                    </div>
                </div>
            }
            {
                productDetails && productDetails.stock === 0 &&
                <div className={styles.productSellOutPage}>
                    <div className={styles.sellOutText}>商品已下架</div>
                </div>
            }
        </>
    )
}

export default observer(ProductPurchase);
