import { useState, useEffect } from 'react';
import styles from './share.module.less';
import ShareMusic from './ShareMusic/ShareMusic';
import ShareCode from './ShareCode/ShareCode';
import ShareCyberAvatar from './ShareCyberAvatar/ShareCyberAvatar';
import BasicSharePage from './BasicSharePage/BasicSharePage';
import BasicShareWithSound from './BasicShareWithSound/BasicShareWithSound';
import ShareChatAgent from './ShareChatAgent/ShareChatAgent';
import ShareMultiImages from './ShareMultiImages/ShareMultiImages';
import { getShareLink } from '@/api/userLikeObject';
import { getUserWork, getWechatShareAuthInfo } from '@/api/userWork';
import { useParams } from 'react-router-dom';
import SongInfo from '@/base/ShareData/SongInfo';
import CodeInfo from '@/base/ShareData/CodeInfo';
import CyberAvatarInfo from '@/base/ShareData/CyberAvatarInfo';
import BasicSharePageInfo from '@/base/ShareData/BasicSharePageInfo';
import BasicShareWithSoundInfo from '@/base/ShareData/BasicShareWithSoundInfo';
import ChatAgentInfo from '@/base/ShareData/ChatAgentInfo';
import MultiImagesInfo from '@/base/ShareData/MultiImagesInfo';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import service from '@/services/axios';
import ShareFooterIcon from '@/assets/img/share_page/share_footer.svg';

// 定义 ShareInfo 类型  
type ShareInfo = SongInfo | CodeInfo | CyberAvatarInfo | BasicSharePageInfo | BasicShareWithSoundInfo | ChatAgentInfo | MultiImagesInfo;

const Share = () => {
    const { userInfoStore } = useStores();
    const [shareType, setShareType] = useState<string>('');
    const [shareInfo, setShareInfo] = useState<ShareInfo | null>(null);
    const { md5 } = useParams();

    // 处理视频封面
    const handleVideoCover = async (url: string) => {
        try {
            // 1. 创建视频元素截取第一帧
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous';
            video.src = url;

            // 等待视频元数据加载
            await new Promise((resolve, reject) => {
                video.addEventListener('loadedmetadata', resolve);
                video.addEventListener('error', reject);
            });

            // 定位到第一帧
            video.currentTime = 0.1;

            // 等待定位完成
            await new Promise((resolve) => video.addEventListener('seeked', resolve));

            // 2. 绘制到 canvas
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx!.drawImage(video, 0, 0, canvas.width, canvas.height);

            // 3. 转换为 Blob
            const blob = await new Promise<Blob | null>((resolve) =>
                canvas.toBlob(resolve, 'image/jpeg', 0.8)
            );

            if (!blob) throw new Error('Failed to capture frame');

            // 4. 上传到服务器
            const formData = new FormData();
            formData.append('file', blob, 'cover.jpg');

            const uploadRes: any = await service.post(
                `/uploadfile?dir=${encodeURIComponent('share_covers')}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (uploadRes.status !== 'success') throw new Error('Upload failed');

            return uploadRes.data.file_url;
        } catch (error) {
            console.error('视频封面处理失败:', error);
            return url; // 失败时返回原始 URL
        }
    };

    const getShareInfo = async () => {
        if (!md5) {
            console.error('md5 is undefined');
            return;
        }
        try {
            let useId = -1;
            if (userInfoStore.userInfoData.id !== 0) {
                useId = userInfoStore.userInfoData.id;
            }
            const res = await getUserWork(md5, useId);
            if (res.status === "success") {
                const userName = res.data.user_name || '小朋友';
                setShareType(res.data.work_type);
                if (res.data.work_type === 'music') {
                    const songInfo = new SongInfo(SongInfo.toCamelCaseObj(res.data.work_info))
                    setShareInfo(songInfo);
                    let desc = '';
                    if (songInfo.authorName) {
                        desc = songInfo.authorName + '刚做了首歌，快来听听吧！';
                    } else {
                        desc = userName + '刚刚做了一首歌，快来听听吧！'
                    }
                    initWeChatShare(songInfo.name, desc, songInfo.coverUrl);
                } else if (res.data.work_type === 'code') {
                    const codeInfo = new CodeInfo(CodeInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(codeInfo);
                    let desc = '';
                    if (codeInfo.authorName) {
                        desc = codeInfo.authorName + '刚刚做了一个游戏，快来看看吧！';
                    } else {
                        desc = userName + '刚刚做了一个游戏，快来看看吧！';
                    }
                    const imgUrl = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/cdc294a75c8469827d3c69d085af9c3f.png';
                    initWeChatShare('好玩的接金币游戏', desc, imgUrl);
                } else if (res.data.work_type === 'cyberAvatar') {
                    const avatarInfo = new CyberAvatarInfo(CyberAvatarInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(avatarInfo);
                    let desc = '';
                    if (avatarInfo.authorName) {
                        desc = avatarInfo.authorName + '刚刚做了头像和签名档，快来看看吧！';
                    } else {
                        desc = userName + '刚刚做了头像和签名档，快来看看吧！';
                    }
                    initWeChatShare(avatarInfo.name, desc, avatarInfo.avatarUrl);
                } else if (res.data.work_type === 'basicPost') {
                    const postInfo = new BasicSharePageInfo(BasicSharePageInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(postInfo);
                    let desc = '分享一篇文章';
                    initWeChatShare(postInfo.title, desc, postInfo.imgUrl);
                } else if (res.data.work_type === 'basicWithSound') {
                    const postInfo = new BasicShareWithSoundInfo(BasicShareWithSoundInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(postInfo);
                    let desc = postInfo.shareDescription || '分享一篇文章';
                    // 判断是否需要处理视频
                    let finalImgUrl = postInfo.imgUrl;
                    if (postInfo.imgUrl?.endsWith('.mp4')) {
                        finalImgUrl = postInfo.shareCoverUrl
                    }
                    initWeChatShare(postInfo.title, desc, finalImgUrl);
                } else if (res.data.work_type === 'chatAgent') {
                    const chatAgentInfo = new ChatAgentInfo(ChatAgentInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(chatAgentInfo);
                    const imgUrl = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/3c289ad23b2108620f0b12ad5e41662f.jpeg';
                    let imgUrl1 = 'https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/0b710cb78044b1236770a92b44368278.png';
                    if (chatAgentInfo.shareScene === 'careerSimulator') {
                        initWeChatShare('智能体', chatAgentInfo.shareDescription, imgUrl1);
                    } else if (chatAgentInfo.shareScene === 'customAgent') {
                        initWeChatShare('智能体', chatAgentInfo.shareDescription, imgUrl1);
                    } else {
                        initWeChatShare("答题挑战", chatAgentInfo.shareDescription, imgUrl);
                    }
                } else if (res.data.work_type === 'multiImages') {
                    const multiImagesInfo = new MultiImagesInfo(MultiImagesInfo.toCamelCaseObj(res.data.work_info));
                    setShareInfo(multiImagesInfo);
                    let desc = multiImagesInfo.shareDescription || '分享一组图片';
                    initWeChatShare(multiImagesInfo.title, desc, multiImagesInfo.imgUrl1);
                }
            }
        } catch (error) {
            console.error('Error getting song info:', error);
        }
    }

    const initWeChatShare = async (title: string, desc: string, imgUrl: string) => {
        console.log('initWeChatShare', title, desc, imgUrl);
        // 获取当前页面的url
        const currentUrl = window.location.href.split("#")[0];

        try {
            const res: any = await getWechatShareAuthInfo(currentUrl);
            if (res.status === 'success') {
                wx.config({
                    debug: false,
                    appId: "wx903d0bd19b6cbd96",
                    timestamp: res.data.timestamp,
                    nonceStr: res.data.noncestr,
                    signature: res.data.signature,
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                });

                wx.ready(() => {
                    wx.updateAppMessageShareData({
                        title: title,
                        desc: desc,
                        link: currentUrl,
                        imgUrl: imgUrl,
                        success: function () {
                            console.log('分享成功');
                        }
                    });
                    wx.updateTimelineShareData({
                        title: title,
                        link: currentUrl,
                        imgUrl: imgUrl,
                        success: function () {
                            console.log('分享成功');
                        }
                    });
                });

            }
        } catch (error) {
            console.error('Error getting wechat signature:', error);
        }
    }

    useEffect(() => {
        getShareInfo();
    }, [md5]);
    return (
        <div className={styles.sharePage}>
            <div className={styles.shareBox}>
                {shareType === 'music' && <ShareMusic songInfo={shareInfo as SongInfo} />}
                {shareType === 'code' && <ShareCode codeInfo={shareInfo as CodeInfo} />}
                {shareType === 'cyberAvatar' && <ShareCyberAvatar cyberAvatarInfo={shareInfo as CyberAvatarInfo} />}
                {shareType === 'basicPost' && <BasicSharePage basicSharePageInfo={shareInfo as BasicSharePageInfo} />}
                {shareType === 'basicWithSound' && <BasicShareWithSound basicShareWithSoundInfo={shareInfo as BasicShareWithSoundInfo} />}
                {shareType === 'chatAgent' && <ShareChatAgent chatAgentInfo={shareInfo as ChatAgentInfo} />}
                {shareType === 'multiImages' && <ShareMultiImages multiImagesInfo={shareInfo as MultiImagesInfo} />}
            </div>
            <div className={styles.shareFooter}>
                <img src={ShareFooterIcon} alt="share_footer" className={styles.footerIcon} />
                <a href="https://agi4kids.cn/?source=sharing" target="_blank" rel="noreferrer" className={styles.footerLink}>
                    快让孩子来艾宝学AI
                </a>
            </div>
        </div>
    )
}

export default observer(Share);