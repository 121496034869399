import React, { useState, useEffect } from 'react';
import styles from './modifyAgentConfig.module.less';
import { ChatMessage } from '@/base/ChatMessage';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/playground/add_preset_question.svg';
import DelPresetQuestionIcon from '@/assets/img/playground/del_preset_question.svg';
import { Select, message, Checkbox } from 'antd';
import { updateUserWork } from "@/api/userWork";
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import CancelIcon from '@/assets/img/cuowu.svg';
import mammoth from 'mammoth';
import ChatData from '@/base/PlaygroundData/ChatData';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { getAllUserWorks } from '@/api/userWork';
import DataDefinition from "@/base/DataDefinition";
import DocumentData from '@/base/DocumentData';
import UploadIcon from '@/assets/img/chat_component/upload_icon.svg';
import { cloneDeep } from 'lodash';
import SpeechToText from '@/components/SpeechToText/SpeechToText';

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface ModifyAgentConfigProps {
    modifyItem: ChatData,
    setIsModifyAgentConfigOpen: (isOpen: boolean) => void,
    updateChatAgentInfo: () => void,
}

const ModifyAgentConfig: React.FC<ModifyAgentConfigProps> = ({
    modifyItem,
    setIsModifyAgentConfigOpen,
    updateChatAgentInfo,
}) => {
    const { userInfoStore, playgroundStore } = useStores();
    const [headerText, setHeaderText] = useState<string>('');
    const [agentName, setAgentName] = useState('');
    const [agentAvatar, setAgentAvatar] = useState('');
    const [presetQuestions, setPresetQuestions] = useState<ChatMessage[]>([]);
    const [ttsVoice, setTtsVoice] = useState('BV033_streaming');
    const [isStream, setIsStream] = useState(true);
    const [documentList, setDocumentList] = useState<DocumentData[]>([]);
    const [currentDocIndex, setCurrentDocIndex] = useState(0);
    const [agentRole, setAgentRole] = useState<string>('');
    const [agentSkill, setAgentSkill] = useState<string>('');
    const [agentRule, setAgentRule] = useState<string>('');
    const [isEditingDoc, setIsEditingDoc] = useState(false);

    useEffect(() => {
        setHeaderText(modifyItem.workInfo?.headerText || '');
        setAgentName(modifyItem.workInfo?.agentName || '');
        setAgentAvatar(modifyItem.workInfo?.agentAvatar || '');
        setPresetQuestions(modifyItem.workInfo?.presetMessages || []);
        setTtsVoice(modifyItem.workInfo?.ttsVoice || 'BV033_streaming');
        setIsStream(modifyItem.workInfo?.isStream || true);
        setAgentRole(modifyItem.workInfo?.agentRole || '');
        setAgentSkill(modifyItem.workInfo?.agentSkill || '');
        setAgentRule(modifyItem.workInfo?.agentRule || '');
        setDocumentList(modifyItem.workInfo?.documentList || []);
        setCurrentDocIndex(modifyItem.workInfo?.currentDocIndex || 0);
    }, [modifyItem])

    const convertPromptToJson = () => {
        const data = {
            "角色": agentRole,
            "技能": agentSkill,
            "规范": agentRule
        };
        const prompt = JSON.stringify(data);
        return prompt;
    };

    const handleAddPresetQuestion = () => {
        const newPresetQuestion = new ChatMessage({});
        setPresetQuestions([...presetQuestions, newPresetQuestion]);
    }

    const handleDelPresetQuestion = (id: number) => {
        const newPresetQuestions = presetQuestions.filter((question) => question.id !== id);
        setPresetQuestions(newPresetQuestions);
    }

    const updateAgent = async () => {
        if (!agentName) {
            message.error('请输入智能体名称');
            return;
        }
        const newWorkInfo = (new AIChatAgentRegionContent({
            systemPrompt: convertPromptToJson(),
            agentName: agentName,
            agentRole: agentRole,
            agentSkill: agentSkill,
            agentRule: agentRule,
            agentMode: 'advanced',
            isStream: isStream,
            ttsVoice: ttsVoice,
            presetMessages: presetQuestions,
            documentList: documentList,
            currentDocIndex: currentDocIndex,
            chatList: modifyItem.workInfo?.chatList || [],
        }));
        const newAgent = new ChatData({
            id: modifyItem.id,
            md5: modifyItem.md5,
            userId: modifyItem.userId,
            userName: modifyItem.userName,
            workType: modifyItem.workType,
            workInfo: newWorkInfo,
            createTime: modifyItem.createTime,
        });
        try {
            const res = await updateUserWork(newAgent);
            if (res.status === 'success') {
                message.success('修改成功');
                updateChatAgentInfo();
                getAllAgents();
                onCancel();
            }
        } catch (error) {
            message.error('修改失败');
        }
    }

    //获取所有的智能体
    const getAllAgents = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_agent");
            if (res.status === "success") {
                const agentItems = res.data.map((item: any) => new ChatData(DataDefinition.toCamelCaseObj(item)));
                if (agentItems.length > 0) {
                    playgroundStore.setUserAgentList(agentItems.reverse());
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onCancel = () => {
        setIsModifyAgentConfigOpen(false);
        setHeaderText('');
        setAgentName('');
        setAgentAvatar('');
        setPresetQuestions([]);
        setTtsVoice('BV033_streaming');
        setIsStream(true);
        setAgentRole('');
        setAgentSkill('');
        setAgentRule('');
        setDocumentList([]);
        setCurrentDocIndex(0);
    }

    const readFileContent = async (file: any, url: string) => {
        // 判断文件类型  
        const fileType = file.type;

        if (fileType === 'text/plain') {
            // 处理 .txt 文件  
            const text = await file.text();
            const newDocument = new DocumentData({
                docContent: text,
                docName: file.name,
                docUrl: url
            });
            setCurrentDocIndex(documentList.length);
            setDocumentList([...documentList, newDocument]);
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // 处理 .docx 文件  
            const arrayBuffer = await file.arrayBuffer();
            const { value } = await mammoth.extractRawText({ arrayBuffer });
            const newDocument = new DocumentData({
                docContent: value,
                docName: file.name,
                docUrl: url
            });
            setCurrentDocIndex(documentList.length);
            setDocumentList([...documentList, newDocument]);
        } else {
            message.error('文件类型不支持');
        }
    };

    const handleDocItemClick = (index: number) => {
        setCurrentDocIndex(index);
        setIsEditingDoc(false);
    }

    const propsUpload = {
        isDoc: true,
    };

    return (
        <div className={styles.modifyAgentConfigBox}>
            <div className={styles.cancelIcon} onClick={onCancel}>
                <img src={CancelIcon} />
            </div>
            <div className={styles.content}>
                <div className={styles.mainTitle}>修改AI智能体配置</div>
                <div className={styles.agentAvatarBox}>
                    <img src={agentAvatar} className={styles.agentAvatar} />
                    <div className={styles.uploadAvatar}>
                        <UploadMedia
                            successCallBack={(url) => {
                                setAgentAvatar(url);
                            }}
                            icon={UploadAgentAvatarIcon}
                            accept_str="image/*"
                            dir="avatar"
                            isMultiple={false}
                        />
                    </div>
                </div>
                <div className={styles.agentNameBox}>
                    <div className={styles.agentNameTitle}>智能体名称</div>
                    <input
                        type="text"
                        className={styles.agentNameInput}
                        placeholder="请输入智能体名称"
                        value={agentName}
                        onChange={(e) => setAgentName(e.target.value)}
                    />
                </div>
                <div className={styles.uploadDocBox}>
                    <div className={styles.uploadDocTitle}>知识库文档</div>
                    <div className={styles.docListTab}>
                        <div className={styles.docList}>
                            {documentList.map((item, index) => (
                                <div
                                    key={item.id}
                                    className={`${styles.docItem} ${currentDocIndex === index ? styles.docItemActive : ''}`}
                                    onClick={() => handleDocItemClick(index)}
                                >
                                    <div className={styles.docItemName}>{item.docName}</div>
                                    <div
                                        className={styles.docItemDel}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const newDocumentList = documentList.filter((doc, i) => i !== index);
                                            setDocumentList(newDocumentList);
                                            if (currentDocIndex === index) {
                                                setCurrentDocIndex(0);
                                            }
                                        }}
                                    >
                                        <img src={CancelIcon} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <UploadMedia
                            icon={UploadIcon}
                            successCallBack={(url, file) => {
                                readFileContent(file, url);
                            }}
                            btn_text="上传文档"
                            accept_str=".txt,.docx"
                            dir="docs"
                            isMultiple={false}
                            uploadProps={propsUpload}
                        />
                    </div>
                    {documentList.length === 0 && (
                        <div className={styles.noDocTip}>
                            请上传知识库文档
                        </div>
                    )}
                    {documentList.length > 0 && (
                        <>
                            {
                                isEditingDoc &&
                                <div className={styles.docEditAreaBox}>
                                    <textarea
                                        className={styles.docEditArea}
                                        placeholder="请输入文档内容"
                                        value={documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                        onChange={(e) => {
                                            const newDocumentList = cloneDeep(documentList);
                                            newDocumentList[currentDocIndex].docContent = e.target.value;
                                            setDocumentList(newDocumentList);
                                        }}
                                    />
                                    <div className={styles.btn}>
                                        <SpeechToText
                                            inputMessage={documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                            setInputMessage={(value) => {
                                                const newDocumentList = cloneDeep(documentList);
                                                newDocumentList[currentDocIndex].docContent = value;
                                                setDocumentList(newDocumentList);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={styles.saveBtn}
                                        onClick={() => {
                                            setIsEditingDoc(false);
                                        }}
                                    >
                                        保存
                                    </div>
                                </div>
                            }
                            {
                                !isEditingDoc &&
                                <div
                                    className={styles.docContentBox}
                                >
                                    <div className={styles.docContent}>
                                        {documentList[currentDocIndex] ? documentList[currentDocIndex].docContent : ''}
                                    </div>
                                    <div
                                        className={styles.editBtn}
                                        onClick={() => setIsEditingDoc(true)}
                                    >
                                        编辑
                                    </div>
                                </div>
                            }
                        </>
                    )}
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>角色描述</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入角色描述"
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                    />
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>技能描述</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入技能描述"
                        value={agentSkill}
                        onChange={(e) => setAgentSkill(e.target.value)}
                    />
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>规范描述</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入规范描述"
                        value={agentRule}
                        onChange={(e) => setAgentRule(e.target.value)}
                    />
                </div>
                <div className={styles.addPresetQuestionsBox}>
                    <div className={styles.addPresetQuestionsTitle}>
                        <span>添加预设问题</span>
                        <div
                            className={styles.addPresetQuestionsBtn}
                            onClick={handleAddPresetQuestion}
                        >
                            <img src={AddPresetQuestionIcon} />
                            添加
                        </div>
                    </div>
                    <div className={styles.presetQuestionsList}>
                        {presetQuestions.map((question, index) => (
                            <div key={question.id} className={styles.presetQuestion}>
                                <div className={styles.presetQuestionTitle}>预设问题{index + 1}</div>
                                <div className={styles.inputBox}>
                                    <input
                                        type="text"
                                        className={styles.presetQuestionInput}
                                        placeholder="请输入问题"
                                        value={question.rawContent}
                                        onChange={(e) => {
                                            question.rawContent = e.target.value;
                                            setPresetQuestions([...presetQuestions]);
                                        }}
                                    />
                                    <div
                                        className={styles.delPresetQuestion}
                                        onClick={() => handleDelPresetQuestion(question.id)}
                                    >
                                        <img src={DelPresetQuestionIcon} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.agentNameBox}>
                    <div className={styles.agentNameTitle}>欢迎语</div>
                    <input
                        type="text"
                        className={styles.agentNameInput}
                        placeholder="请输入欢迎语"
                        value={headerText}
                        onChange={(e) => setHeaderText(e.target.value)}
                    />
                </div>
                <div className={styles.voiceSelectBox}>
                    <div className={styles.voiceSelectTitle}>AI回答音色</div>
                    <Select
                        value={ttsVoice}
                        style={{ width: 120 }}
                        onChange={(value) => setTtsVoice(value)}
                        className={styles.voiceSelect}
                    >
                        {voiceList.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className={styles.createAgentBtnBox}>
                <div
                    className={styles.createAgentBtn}
                    onClick={updateAgent}
                >
                    保存修改
                </div>
            </div>
        </div>
    )
}

export default observer(ModifyAgentConfig);
