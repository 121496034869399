import React, { useState, useEffect } from 'react';
import styles from './modifyBasicAgentConfig.module.less';
import { ChatMessage } from '@/base/ChatMessage';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/playground/add_preset_question.svg';
import DelPresetQuestionIcon from '@/assets/img/playground/del_preset_question.svg';
import { Select, message, Checkbox } from 'antd';
import { updateUserWork } from "@/api/userWork";
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import CancelIcon from '@/assets/img/cuowu.svg';
import mammoth from 'mammoth';
import ChatData from '@/base/PlaygroundData/ChatData';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { getAllUserWorks } from '@/api/userWork';
import DataDefinition from "@/base/DataDefinition";

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface ModifyAgentConfigProps {
    modifyItem: ChatData,
    setIsModifyBasicAgentConfigOpen: (isOpen: boolean) => void,
    updateChatAgentInfo: () => void,
}

const ModifyAgentConfig: React.FC<ModifyAgentConfigProps> = ({
    modifyItem,
    setIsModifyBasicAgentConfigOpen,
    updateChatAgentInfo,
}) => {
    const { userInfoStore, playgroundStore } = useStores();
    const [agentRole, setAgentRole] = useState<string>('');
    const [agentSkill, setAgentSkill] = useState<string>('');
    const [agentRule, setAgentRule] = useState<string>('');

    useEffect(() => {
        setAgentRole(modifyItem.workInfo?.agentRole || '');
        setAgentSkill(modifyItem.workInfo?.agentSkill || '');
        setAgentRule(modifyItem.workInfo?.agentRule || '');
    }, [modifyItem])

    const convertPromptToJson = () => {
        const data = {
            "角色": agentRole,
            "技能": agentSkill,
            "规范": agentRule
        };
        const prompt = JSON.stringify(data);
        return prompt;
    };

    const updateAgent = async () => {
        const newWorkInfo = (new AIChatAgentRegionContent({
            systemPrompt: convertPromptToJson(),
            agentName: '智能体',
            agentRole: agentRole,
            agentSkill: agentSkill,
            agentRule: agentRule,
            agentMode: 'basic',
            isStream: true,
            chatList: modifyItem.workInfo?.chatList || [],
        }));
        const newAgent = new ChatData({
            id: modifyItem.id,
            md5: modifyItem.md5,
            userId: modifyItem.userId,
            userName: modifyItem.userName,
            workType: modifyItem.workType,
            workInfo: newWorkInfo,
            createTime: modifyItem.createTime,
        });
        try {
            const res = await updateUserWork(newAgent);
            if (res.status === 'success') {
                message.success('修改成功');
                updateChatAgentInfo();
                getAllAgents();
                onCancel();
            }
        } catch (error) {
            message.error('修改失败');
        }
    }

    //获取所有的智能体
    const getAllAgents = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_agent");
            if (res.status === "success") {
                const agentItems = res.data.map((item: any) => new ChatData(DataDefinition.toCamelCaseObj(item)));
                if (agentItems.length > 0) {
                    playgroundStore.setUserAgentList(agentItems.reverse());
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onCancel = () => {
        setIsModifyBasicAgentConfigOpen(false);
        setAgentRole('');
        setAgentSkill('');
        setAgentRule('');
    }

    return (
        <div className={styles.modifyAgentConfigBox}>
            <div className={styles.cancelIcon} onClick={onCancel}>
                <img src={CancelIcon} />
            </div>
            <div className={styles.content}>
                <div className={styles.mainTitle}>修改AI智能体配置</div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>角色描述</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入角色描述"
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                    />
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>技能描述</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入技能描述"
                        value={agentSkill}
                        onChange={(e) => setAgentSkill(e.target.value)}
                    />
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>规范描述</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入规范描述"
                        value={agentRule}
                        onChange={(e) => setAgentRule(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.createAgentBtnBox}>
                <div
                    className={styles.createAgentBtn}
                    onClick={updateAgent}
                >
                    保存修改
                </div>
            </div>
        </div>
    )
}

export default observer(ModifyAgentConfig);
