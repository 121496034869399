import React, { useEffect, useState } from 'react';
import styles from "./learningProgress.module.less";
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { Table } from 'antd';

const LearningProgressList = [
    {
        course_stage: '试听课1',
        course_name: '为最爱的角色写歌',
        progress: '50%',
    },
    {
        course_stage: '试听课2',
        course_name: '赛博头像',
        progress: '80%',
    },
    {
        course_stage: '试听课3',
        course_name: '赛博头像',
        progress: '30%',
    },
    {
        course_stage: '第1课',
        course_name: '跟大语言模型聊起来',
        progress: '60%',
    },
]

const LearningProgress: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [learningProgressList, setLearningProgressList] = useState<any[]>([]);
    const columns = [
        {
            title: '课程阶段',
            dataIndex: 'course_stage',
            key: 'course_stage',
        },
        {
            title: '课程名称',
            dataIndex: 'course_name',
            key: 'course_name',
        },
        {
            title: '学习进度',
            dataIndex: 'progress',
            key: 'progress',
        }
    ];

    useEffect(() => {
        setLearningProgressList(LearningProgressList);
    }, [])
    return (
        <div className={styles.learningProgressPage}>
            <div className={styles.goBack} onClick={() => navigate('/user')}>
                <img src={GoBackIcon} alt="go back" />
            </div>

            <div className={styles.content}>
                <div className={styles.mainTitle}>学习进度</div>
                <div className={styles.subTitle}>
                    这里查看你的学习进度，继续加油哦！
                </div>
                <div className={styles.learningProgressBox}>
                    <div className={styles.learningProgressIcon}>
                        {/* <img src={UserPaymentsIcon} /> */}
                    </div>
                    <div className={styles.title}>

                    </div>
                    <div className={styles.learningProgressList}>
                        <Table
                            columns={columns}
                            dataSource={learningProgressList}
                            pagination={false}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default observer(LearningProgress);