import React, { useState } from 'react';
import { Modal, message, Button } from 'antd';
import styles from './createBasicAgentModal.module.less';
import { createUserWork } from '@/api/userWork';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import AIChatAgentRegionContent from '@/base/ElementData/AIChatAgentRegionContent';
import DataDefinition from "@/base/DataDefinition";
import { useNavigate } from 'react-router-dom';
import ChatData from '@/base/PlaygroundData/ChatData';
import { generateMD5 } from '@/utils/utils';
import SpeechToText from '@/components/SpeechToText/SpeechToText';
import ChatAgentIcon from '@/assets/img/chat_component/chat_agent_icon.svg';
import CloseModalIcon from '@/assets/img/playground/close_modal.svg';

interface CreateBasicAgentModalProps {
    isCreateBasicAgentModalOpen: boolean;
    setIsCreateBasicAgentModalOpen: (isCreateBasicAgentModalOpen: boolean) => void;
}

interface FirstStepContentProps {
    agentRole: string;
    setAgentRole: (agentRole: string) => void;
}

interface SecondStepContentProps {
    agentSkill: string;
    setAgentSkill: (agentSkill: string) => void;
}

interface ThirdStepContentProps {
    agentRule: string;
    setAgentRule: (agentRule: string) => void;
}

const CreateBasicAgentModal: React.FC<CreateBasicAgentModalProps> = ({
    isCreateBasicAgentModalOpen,
    setIsCreateBasicAgentModalOpen
}) => {
    const navigate = useNavigate();
    const { userInfoStore, playgroundStore } = useStores();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [agentRole, setAgentRole] = useState<string>('');
    const [agentSkill, setAgentSkill] = useState<string>('');
    const [agentRule, setAgentRule] = useState<string>('');

    const genNewAgentMd5 = () => {
        if (playgroundStore.userAgentList.length === 0) {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat0`);
        } else {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat${playgroundStore.userAgentList[0].id + 1}`);
        }
    }

    const convertPromptToJson = () => {
        const data = {
            "角色": agentRole,
            "技能": agentSkill,
            "规范": agentRule
        };
        const prompt = JSON.stringify(data);
        return prompt;
    };

    const createNewAgent = async (md5: string) => {
        try {
            const newChatAgent = new ChatData({
                md5: md5,
                userId: userInfoStore.userInfoData.id,
                userName: userInfoStore.userInfoData.name,
                workType: "playground_agent",
                workInfo: new AIChatAgentRegionContent({
                    systemPrompt: convertPromptToJson(),
                    agentName: '智能体',
                    agentRole: agentRole,
                    agentSkill: agentSkill,
                    agentRule: agentRule,
                    agentMode: 'basic',
                    isStream: true,
                }),
            });

            const createRes: any = await createUserWork(newChatAgent);
            if (createRes.status === 'success') {
                createRes.data = new ChatData(DataDefinition.toCamelCaseObj(createRes.data));
                playgroundStore.setUserAgentList([createRes.data, ...playgroundStore.userAgentList]);
                navigate(`/playground/chat/${createRes.data.md5}`);
                onCancel();
            }

        } catch (error) {
            message.error('发生错误，请重试');
            throw error;
        }
    };

    const steps = [
        {
            title: 'First',
            content: <FirstStepContent
                agentRole={agentRole}
                setAgentRole={setAgentRole}
            />,
        },
        {
            title: 'Second',
            content: <SecondStepContent
                agentSkill={agentSkill}
                setAgentSkill={setAgentSkill}
            />,
        },
        {
            title: 'Third',
            content: <ThirdStepContent
                agentRule={agentRule}
                setAgentRule={setAgentRule}
            />,
        },
    ];

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    }

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    }

    const onCancel = () => {
        setIsCreateBasicAgentModalOpen(false);
        setCurrentStep(0);
        setAgentRole('');
        setAgentSkill('');
        setAgentRule('');
    }

    return (
        <Modal
            centered
            open={isCreateBasicAgentModalOpen}
            onCancel={onCancel}
            footer={null}
            className={styles.createAgentModal}
            closeIcon={<img src={CloseModalIcon} alt="CloseModalIcon" className={styles.closeModalIcon} />}
        >
            <div className={styles.createAgentContainer}>
                <div className={styles.stepsContentBox}>
                    {steps[currentStep].content}
                </div>
                <div className={styles.stepsAction}>
                    {currentStep > 0 && (
                        <Button onClick={() => prevStep()} className={styles.prevBtn}>
                            上一步
                        </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <Button type="primary" onClick={() => nextStep()} className={styles.nextBtn}>
                            下一步
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="primary" onClick={() => createNewAgent(genNewAgentMd5())} className={styles.nextBtn}>
                            创建
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default observer(CreateBasicAgentModal);

const FirstStepContent: React.FC<FirstStepContentProps> = ({
    agentRole, setAgentRole
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 角色描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入角色描述"
                        value={agentRole}
                        onChange={(e) => setAgentRole(e.target.value)}
                    />
                    <div className={styles.btn}>
                        <SpeechToText
                            inputMessage={agentRole}
                            setInputMessage={setAgentRole}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const SecondStepContent: React.FC<SecondStepContentProps> = ({
    agentSkill, setAgentSkill
}) => {

    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 技能描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>
                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入技能描述"
                        value={agentSkill}
                        onChange={(e) => setAgentSkill(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentSkill}
                                setInputMessage={setAgentSkill}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ThirdStepContent: React.FC<ThirdStepContentProps> = ({
    agentRule, setAgentRule
}) => {
    return (
        <div className={styles.stepContent}>
            <div className={styles.agentSystemPromptBox}>
                <div className={styles.agentSystemPromptTitle}>
                    {`智能体创建 ----> 规范描述`}
                    <img src={ChatAgentIcon} alt="ChatAgentIcon" className={styles.inspirationIcon} />
                </div>
                <div className={styles.agentSystemPromptInputBox}>

                    <textarea
                        className={styles.agentSystemPromptInput}
                        placeholder="请输入规范描述"
                        value={agentRule}
                        onChange={(e) => setAgentRule(e.target.value)}
                    />
                    <div className={styles.btnArea}>
                        <div className={styles.btn}>
                            <SpeechToText
                                inputMessage={agentRule}
                                setInputMessage={setAgentRule}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
