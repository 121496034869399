import React from 'react';
import styles from './paymentSuccess.module.less';
import { useMediaQuery } from 'react-responsive';
import PaySuccessImg from '@/assets/img/product_details/pay_success.png';
import PCSuccessHeadImg from '@/assets/img/payment_success/pc_success_head.svg';
import ServiceQRCodeImg from '@/assets/img/payment_success/service_qrcode.jpg';

const PaymentSuccess: React.FC = () => {
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNotMobile = useMediaQuery({ query: '(min-width: 769px)' });
    return (
        <>
            {isMobile &&
                <div className={styles.paySuccessPage}>
                    <img src={PaySuccessImg} className={styles.paySuccessImg} />
                </div>
            }
            {isNotMobile &&
                <div className={styles.pcSuccessPage}>
                    <div className={styles.pcSuccessBox}>
                        <img src={PCSuccessHeadImg} className={styles.pcSuccessHeadImg} />
                        <img src={ServiceQRCodeImg} className={styles.serviceQRCodeImg} />
                        <div className={styles.process}>
                            <div className={styles.processTitle}>上课流程</div>
                            <div className={styles.processContent}>
                                <div className={styles.processItem}>
                                    <div className={styles.processItemNum}>1</div>
                                    <div className={styles.processItemText}>
                                        请在电脑或者pad上用浏览器访问
                                        <span
                                            className={styles.processItemLink}
                                            onClick={
                                                () => { window.open('https://agi4kids.cn/home?utm=zlh') }
                                            }
                                        >
                                            https://agi4kids.cn/home?utm=zlh
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.processItem}>
                                    <div className={styles.processItemNum}>2</div>
                                    <div className={styles.processItemText}>进入艾宝AI的主页，点击【进入应用】</div>
                                </div>
                                <div className={styles.processItem}>
                                    <div className={styles.processItemNum}>3</div>
                                    <div className={styles.processItemText}>选择手机号登录，收到验证码后就可以进入应用。</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default PaymentSuccess;
