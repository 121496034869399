import React, { useState, useEffect, useRef } from 'react';
import styles from './recharge.module.less';
import { QRCode } from 'antd';
import { genOrderAndQRcode, getPayment } from '@/api/recharge';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { message, InputNumber, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';

const Recharge: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [diamondNum, setDiamondNum] = useState<number | null>(null);
    const [paymentQRCode, setPaymentQRCode] = useState("暂无");
    const [countdown, setCountdown] = useState(0);
    const pollingInterval = useRef<number | null>(null);
    const timerRef = useRef<number | null>(null);
    const [paymentStatus, setPaymentStatus] = useState<'no_qr_code' | 'show_qr_code' | 'payment_success' | 'qr_code_expired'>('no_qr_code');
    const [isGenOrderLoading, setIsGenOrderLoading] = useState(false);

    const presetDiamondNums = [10, 50, 100, 200, 300];

    const handleGenerateOrder = async () => {
        if (!diamondNum) {
            message.error("请输入充值数量");
            return;
        }
        const data = {
            id: 0,
            user_id: userInfoStore.userInfoData.id,
            product_id: 7,
            alternative_id: userInfoStore.userInfoData.phone || '',
            id_type: "phone",
            total_amount: diamondNum * 0.1,
            original_price: 0.1,
            price: 0.1,
            currency: "CNY",
            status: 1,
            payment_method: "wechat_qrcode",
            openid: "",
            extras: {},
            create_time: 0,
            update_time: 0
        };

        setIsGenOrderLoading(true);

        try {
            const res: any = await genOrderAndQRcode(data);
            if (res.status === 'success') {
                setPaymentQRCode(res.data.code_url);
                setPaymentStatus('show_qr_code');
                if (pollingInterval.current) {
                    clearInterval(pollingInterval.current);
                }
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                startPolling(res.data.payment_id);
                setCountdown(300); // 5 分钟（300 秒）倒计时
            }
        } catch (error) {
            message.error("生成订单失败，请重试")
            console.error('生成订单和二维码失败:', error);
        } finally {
            setIsGenOrderLoading(false);
        }
    };

    const startPolling = (paymentId: number) => {
        pollingInterval.current = setInterval(async () => {
            try {
                const res = await getPayment(paymentId);
                if (res.status === 'success') {
                    if (res.data.status === 1) {
                        if (pollingInterval.current) {
                            clearInterval(pollingInterval.current);
                        }
                        pollingInterval.current = null;
                        userInfoStore.refreshUserAssets(userInfoStore.userInfoData.id);
                        message.success('支付成功');
                        setPaymentStatus('payment_success');
                        setPaymentQRCode("暂无");
                        if (timerRef.current) {
                            clearTimeout(timerRef.current);
                        }
                    }
                }
            } catch (error) {
                console.error('查询订单状态失败:', error);
            }
        }, 2000);
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current as number);
            pollingInterval.current = null;
        }
    };

    useEffect(() => {
        if (countdown > 0) {
            timerRef.current = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
            };
        } else if (countdown === 0 && paymentStatus === 'show_qr_code') {
            setPaymentStatus('qr_code_expired');
            setPaymentQRCode("暂无");
            message.error('二维码已过期，请重新生成订单');
        }
    }, [countdown, paymentStatus]);

    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, []);

    return (
        <div className={styles.rechargePage}>
            <div className={styles.goBack} onClick={() => navigate('/user')}>
                <img src={GoBackIcon} alt="go back" />
            </div>

            <div className={styles.content}>
                <div className={styles.mainTitle}>充值钻石</div>
                <div className={styles.subTitle}>
                    钻石可用于音乐生成、AI聊天等服务
                </div>
                <div className={styles.rechargeBox}>
                    <div className={styles.rechargeContainer}>
                        <div className={styles.title}>充值数量</div>
                        <div className={styles.diamondOptions}>
                            {presetDiamondNums.map((num) => (
                                <button
                                    key={num}
                                    onClick={() => setDiamondNum(num)}
                                    className={diamondNum === num ? styles.activeOption : styles.option}
                                >
                                    {num}个
                                </button>
                            ))}
                            <InputNumber
                                placeholder="自定义"
                                value={diamondNum}
                                onChange={(value) => setDiamondNum(value)}
                                className={`${styles.customInput} ${styles.option}`}
                                min={0}
                                step={1} // 设置步长为 1
                                precision={0} // 设置精度为 0
                            />
                        </div>
                        <Button
                            onClick={handleGenerateOrder}
                            // disabled={diamondNum === 0 || diamondNum === null}
                            className={`${styles.rechargeButton} `}
                            loading={isGenOrderLoading}
                        >
                            充值
                        </Button>
                        <div className={styles.qrCodeWrapper}>
                            {paymentStatus === 'no_qr_code' && (
                                // <div className={styles.qrCodeArea}>
                                //     <div className={styles.statusText}>暂无二维码</div>
                                // </div>
                                <></>
                            )}
                            {paymentStatus === 'show_qr_code' && (
                                <>
                                    <div className={styles.qrCodeArea}>
                                        <QRCode value={paymentQRCode} className={styles.qrCode} />
                                    </div>
                                    <p className={styles.tip}>请使用微信扫描二维码进行支付</p>
                                    <p className={styles.countdownText}>二维码有效期: {Math.floor(countdown / 60)} 分 {countdown % 60} 秒</p>
                                </>
                            )}
                            {paymentStatus === 'payment_success' && (
                                <div className={styles.qrCodeArea}>
                                    <p className={styles.statusText}>支付成功</p>
                                </div>
                            )}
                            {paymentStatus === 'qr_code_expired' && (
                                <div className={styles.qrCodeArea}>
                                    <p className={styles.statusText}>二维码已过期，请重新生成订单</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default observer(Recharge);